import React, {useEffect, useState} from 'react';
import {Grid, Typography, Dialog, Button, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Slide from "@material-ui/core/Slide";
import { connect } from 'react-redux';
import CreateUpdateInvoiceComponent from '../CreateUpdateInvoiceComponent';
import PreviewAndSendInvoiceComponent from '../PreviewAndSendInvoiceComponent';
import {SendEmailType} from '../../actions/invoiceAccount';
import {setCreateInvoiceAccount, setInvoiceItemObj, setCustomerObject, 
    setShowPreviewInvoiceScreen, setShowSendInvoiceScreenType, setEditInvoiceObj} from '../../actions/invoiceAccount';
import { history } from '../../routers/AppRouter';
import { getUrlAccordingToSelectedComponent } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { useLocation } from 'react-router-dom';
import { setEntityObj } from '../../actions/invoiceAccount';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEditPreviewInvoiceModal = (props) => {

    const [loading, setLoading] = useState(true);
    const isMount = useIsMount();
    const location = useLocation();

    useEffect(() => {
            if(props.showCreateInvoice) {
                setLoading(false);
            }
    }, [props.showCreateInvoice]);


    useEffect(() => {
        if(Object.keys(props.invoiceObj).length > 0 || Object.keys(props.editInvoiceObj).length > 0) {
            setLoading(false);
        }
    }, [props.invoiceObj, props.editInvoiceObj]);

    const closeDialog = () => {
        history.push(getUrlAccordingToSelectedComponent(props.drawerState));
        props.showCreateInvoice && props.setCreateInvoiceAccount(false);
        Object.keys(props.invoiceObj).length > 0 && props.setInvoiceItemObj({});
        Object.keys(props.editInvoiceObj).length > 0 && props.setEditInvoiceObj({});
        props.setCustomerObject({});
        if(props.showPreviewInvoiceScreen) props.setShowPreviewInvoiceScreen(false);
        if(props.showSendInvoiceScreenType !== SendEmailType.NONE) props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        props.handleInvoiceDialogClose();
    }

    return (
        <Dialog
            open={props.openInvoiceDialog}
            TransitionComponent={Transition}
            scroll="paper"
            fullScreen
            style={{scrollbarWidth:'none !important', zIndex:'100'}}
            onClose={closeDialog}>

                <Grid item md={12} style={{background:'#f7f7f7'}}>

                    
                    {loading ? 
                    <Grid item container justifyContent="center">
                        <CircularProgress size={25} style={{marginTop:'32px'}}/>
                    </Grid> 
                    :
                    <>
                        {
                            Object.keys(props.invoiceObj).length > 0 
                            ? 
                            <PreviewAndSendInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                setAddTransaction={props.setAddTransaction}
                                updateTranscItemInTranscList={props.updateTranscItemInTranscList}/> 
                            :
                            <CreateUpdateInvoiceComponent handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                updateInvoiceItemListInClients={props.updateInvoiceItemListInClients}
                                setCallGetInvoiceListApi={props.setCallGetInvoiceListApi}/>}
                    </>
                    }

                </Grid>
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    drawerState: state.drawerState.setDrawerState,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen
});

const mapDispatchToProps = (dispatch) => ({
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    setShowPreviewInvoiceScreen: (previewInvoiceScreen) => dispatch(setShowPreviewInvoiceScreen(previewInvoiceScreen)),
    setShowSendInvoiceScreenType: (sendInvoiceScreenType) => dispatch(setShowSendInvoiceScreenType(sendInvoiceScreenType)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),

    setEntityObj: (entity) => dispatch(setEntityObj(entity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditPreviewInvoiceModal);