import React, {useEffect, useState} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { connect } from 'react-redux';
import { getDueInvoiceListApi } from '../services/authService';
import { consoleToLog, showDate, currencyFormatter, capitalizeFirstLetterOfEachWord, InoviceTypes } from '../util/AppUtil';
import { makeStyles } from '@material-ui/core/styles';
import { useIsMount } from './useIsMount';
import {Grid, Typography, CircularProgress, Button} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { setInvoiceItemObj } from '../actions/invoiceAccount';
import {AccessLevel} from '../util/AppUtil';
import { formatAmount } from '../util/currencyUtil';
import { getEstimateInvoiceDetailsApi } from '../services/authService';
import { history } from '../routers/AppRouter';
import {displayInvoiceTypeLabel} from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    customMarginTop: {
        marginTop: "16px",
    },
    customMarginBottom: {
        marginBottom: "-8px",
    },
    listContainer: {
        width: "100% !important",
        "&:last-child .dropdownDivider": {
            display: "none",
        },
    },
    avatarStyle: {
        width: "28px",
        height: "28px",
        fontSize: "12px",
        fontWeight: "600",
        color: "#2B2B2B",
        marginRight: "-8px",
    },
    list: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        padding: "0px 8px 0px 16px",
        "&:hover": {
            background: "#F9F9F9",
        },
    },
    dividerStyle: {
        background: "#000",
        opacity: "0.1",
    },
    dueInvoiceListContainer: {
        cursor:'pointer',
        boxSizing: "border-box",
        border: "1px solid #ccc",
        padding: "16px 16px 0px 16px",
        borderRadius: "4px",
        marginBottom: "16px",
    },
    invoiceNumber: {
        background: "#d2f2d6",
        padding: "2px 6px",
        borderRadius: "4px",
        color: "#4a8c50",
    },
    dueDate: {
        color: "#8d96a2",
        fontWeight: "500",
        marginLeft: "16px",
    },
    divider: {
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        margin: "8px 0px 10px 0px",
    },
    headingText: {
        color: "#8d96a2",
    },
    plusIcon: {
        marginRight: "14px",
    },
    invoiceType: {
        color: "#747775",
        background: "#ededed",
        padding: "4px 8px",
        borderRadius: "4px",
    },
    clientName: {
        color: "#4a5568",
        fontWeight:500,
        marginTop:'8px'
    },
}))

const ShowInvoicesDrawer = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();

    const [dueInvoicesList, setDueInvoicesList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [total, setTotal] = useState();
    const [items, setItems] = useState([]);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER ? true : false;

    useEffect(() => {
        getDueInvoices();
    }, []);

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                getDueInvoices();
            }
        }
    }, [isMoreLoading]);

    const getDueInvoices = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = props.dueInvoiceObj?.entity_id;
        const daysParam = props.dueInvoiceObj?.data_for.replaceAll(/\s/g,'').replace('Days', '');
        const days_param = daysParam === '90+' ? '90-120' : daysParam;

        getDueInvoiceListApi(invoice_account_id, entity_id, days_param, page)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getDueInvoiceListApi: ", res);
                setLoading(false);
                setItems(res.invoices);

                if(isMoreLoading) {
                    const newItems = dueInvoicesList.concat(res.invoices);
                    setDueInvoicesList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setPage(page + 1);
                    setDueInvoicesList(res.invoices);
                    setTotal(res.total);
                }
            })
            .catch((e) => {
                consoleToLog("Error getDueInvoiceListApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onViewInvoiceClick = async (e, invoice) => {
            e.stopPropagation();
            getInvoiceDetails(invoice);
            props.handleInvoiceDialogOpen();
    }

    const getInvoiceDetails = async(invoice) => {
        const apiPath = `invoice/${invoice.id}`;
        const invoice_account_id = props.selectedAccount.id;

        try {
            const response = await getEstimateInvoiceDetailsApi(invoice_account_id, apiPath);
            const res = response.data;
            consoleToLog('Response getEstimateInvoiceDetailsApi: ', res);
            props.setInvoiceItemObj(res);
            history.push(`/invoice/${invoice.slug}/preview`);
        } catch (error) {
            consoleToLog("Error getEstimateInvoiceDetailsApi", error);     
        }
    
    }

    const loadMoreLogic = () => {
        return (
            <Grid item container>
                <Grid item lg={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <RightToLeftSideDrawer title={'Invoices'}
            openDrawer={props.openInvoicesDrawer}
            closeDrawer={props.handleInvoicesDrawerClose}
            fromDueInvoicesDrawer={true}>
                
                <div style={{ padding: "16px", marginBottom: "18px" }}>
                    {
                        loading ?
                        <Grid item container justifyContent='center' alignItems='center'>
                            <CircularProgress size={25} style={{marginTop:'24px'}}/>
                        </Grid>
                        :
                        <>
                        {
                        dueInvoicesList &&
                        dueInvoicesList.length > 0 ?
                        dueInvoicesList.map((invoice) => {
                            return (
                            <Grid
                                item container
                                direction='column'
                                className={classes.dueInvoiceListContainer}
                                key={invoice.id}
                            >
                                <Grid item container alignItems="center" justifyContent='space-between'>
                                    <Grid item>
                                        <Typography
                                        variant="subtitle1"
                                        >
                                        {<span className='invoice_type_style'>
                                            {displayInvoiceTypeLabel(invoice.invoice_type)}
                                        </span>} 
                                        <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                            {invoice.invoice_type === InoviceTypes.PROFORMA ? invoice.proforma_invoice_number :  invoice.invoice_number}
                                        </span>
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="body2" className={classes.dueDate}>
                                        {showDate(invoice.issue_date)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" className={classes.clientName}>
                                        {invoice.client_name}
                                    </Typography>
                                </Grid>
                                <div className={classes.divider}></div>

                                <Grid
                                    item
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                <Grid item md={4}>
                                    <Typography variant="body2" className={classes.headingText}>
                                    Invoice Total
                                    </Typography>

                                    <Typography variant="body2">
                                    {formatAmount(invoice.total, invoice.currency)}
                                    </Typography>
                                </Grid>

                                {/* <Grid item md={4} style={{ textAlign: "center" }}>
                                    <Typography variant="body2" className={classes.headingText}>
                                    TDS Amount
                                    </Typography>

                                    <Typography variant="body2">
                                    {currencyFormatter.format(invoice.tds_amount)}
                                    </Typography>
                                </Grid> */}

                                    <Grid item md={4} style={{ textAlign: "right" }}>
                                        <Typography variant="body2" className={classes.headingText}>
                                        Amount due
                                        </Typography>

                                        <Typography variant="body2" style={{ color: "red" }}>
                                        {formatAmount(invoice.due, invoice.currency)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid item container
                                        spacing={1}
                                        style={{ marginTop: "16px", marginBottom: "-8px" }}
                                    >

                                        <Grid item md={6}>
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => onViewInvoiceClick(e, invoice)}
                                                fullWidth
                                                style={{ color: "green" }}
                                            >
                                                <VisibilityOutlinedIcon
                                                fontSize="small"
                                                className={classes.plusIcon}
                                                />
                                                View 
                                            </Button>
                                        </Grid>

                                        <Grid item md={6}>
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => props.handleOpenAddPaymentDrawer(e, invoice)}
                                                fullWidth
                                                style={{ color: "green" }}
                                                disabled={accessLevelViewer}
                                            >
                                                <AddCircleOutlineIcon
                                                fontSize="small"
                                                className={classes.plusIcon}
                                                />
                                                Add Payment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            );
                        }) : <Grid item container justifyContent='center'
                                style={{background:'#f7f7f7', padding:'16px', borderRadius:'5px'}}>
                                <Typography variant='body1'>
                                    No Due Invoices Found
                                </Typography>
                            </Grid>
                    }
                    </>
                    }

                    {loadMoreLogic()}
                </div>

        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setInvoiceItemObj : (invoice) => dispatch(setInvoiceItemObj(invoice))
})


export default connect(mapStateToProps, mapDispatchToProps)(ShowInvoicesDrawer);