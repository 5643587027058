
import {getAxios} from './axiosUtil';

export const getInvoiceListApi = (invoice_account_id, page, issue_start_date, issue_end_date, clients, status, type, entities, order_by) => {
    const param = {
        params: {
            page,
            issue_start_date, 
            issue_end_date, 
            clients, 
            status, 
            type,
            entities,
            order_by
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/all`, param);
}

export const getClientListApi = (invoice_account_id, text) => {
    const param = {
        params: {
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/clients`, param);
}

export const getInvoiceAccountsListApi = () => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/user/invoice_accounts/`);
}

export const createInvoiceApi = (invoice_account_id, entity_id, client_id, email_array, invoice_description, subtotal,
    total_tax, total, approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes, 
    show_payment_terms, show_payment_stub, download, tds_type_id, tds_amount, discount, enable_esign, set_number_manually, 
    invoice_number, tcs, reference_number, currency_id, show_upi_qr_code, estimate_id) => {
    const data = {
        email_array,
        invoice_description, 
        subtotal, 
        total_tax, 
        total, 
        approval_status, 
        issue_date, 
        due_date, 
        client_notes, 
        payment_terms, 
        items_details,
        show_client_notes,
        show_payment_terms,
        show_payment_stub,
        download,
        tds_type_id,
        tds_amount,
        discount,
        enable_esign,
        set_number_manually, 
        invoice_number,
        tcs,
        reference_number,
        currency_id,
        show_upi_qr_code,
        estimate_id
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice/${invoice_account_id}/${entity_id}/${client_id}/add`, data);
}

export const getEstimateInvoiceNumberApi = (invoice_account_id, apiStr) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}`);
}

export const addTaxPresetApi = (invoice_account_id, name, rate) => {
    const data = {
        name,
        rate
    } 
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/tax_preset/${invoice_account_id}/add`, data);
}

export const updateInvoiceApi = (invoice_account_id, invoice_id, email_array, invoice_description, subtotal,
    total_tax, total, approval_status, issue_date, due_date, client_notes, payment_terms, items_details, show_client_notes, 
    show_payment_terms, show_payment_stub, download, tds_type_id, tds_amount, discount, entity_id, client_id, enable_esign, 
    set_number_manually, invoice_number, tcs, reference_number, currency_id, show_upi_qr_code, estimate_id) => {
    const data = {
        email_array,
        invoice_description, 
        subtotal, 
        total_tax, 
        total, 
        approval_status, 
        issue_date, 
        due_date, 
        client_notes, 
        payment_terms, 
        items_details,
        show_client_notes,
        show_payment_terms,
        show_payment_stub,
        download,
        tds_type_id,
        tds_amount,
        discount,
        entity_id,
        client_id,
        enable_esign,
        set_number_manually,
        invoice_number,
        tcs,
        reference_number,
        currency_id,
        show_upi_qr_code,
        estimate_id
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/update`, data);
}

export const sendInvoiceEmailApi = (invoice_id, email_array, email_json, invoice_type) => {
    const data = {
        email_array,
        email_json,
        invoice_type
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice/${invoice_id}/send`, data);
}

export const updateEmailSettingsApi = (invoice_account_id, email_settings) => {
    const data = {
        email_settings
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/email_settings`, data);
}

export const clientPreviewApi = (md5, uuid_value, email) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/client/invoice/${uuid_value}/preview?email=${email}`, );
}

export const downloadInvoiceApi = (md5, invoice_id) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/client/invoice/${invoice_id}/download`);
}

export const updateInvoiceDisplaySettingsApi = (invoice_account_id, paramPaymentStub, paramPaymentTerms, paramClientTerms) => {
    
    const display_settings = {
        show_payment_stub: paramPaymentStub,
        show_payment_terms: paramPaymentTerms,
        show_client_notes: paramClientTerms
    }   
    const data = {
        display_settings
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/display_settings`, data);
}

export const loggedInUserDownloadApi = (invoice_account_id, invoice_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/download`);
}

export const addPaymentTransactionApi = (invoice_account_id, invoice_id, amount, payment_method, bank_account_id, remarks, payment_date, 
    send_receipt, convert_to_tax) => {
    
    const data = {
        amount,
        payment_method,
        bank_account_id,
        remarks,
        payment_date,
        send_receipt,
        convert_to_tax
    }
    
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/transaction/add`, data);
}

export const getTransactionsListApi = (invoice_account_id, page, payment_start_date, payment_end_date, payment_method, clients) => {
    const param = {
        params: {
            page,
            payment_start_date, 
            payment_end_date, 
            payment_method, 
            clients
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/transactions`, param);
}

export const updatePaymentTransactionApi = (invoice_account_id, transaction_id, amount, payment_method, bank_account_id, remarks, payment_date) => {
    
    const data = {
        amount,
        payment_method,
        bank_account_id,
        remarks,
        payment_date,
    }
    
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/transaction/${transaction_id}/update`, data);
}

export const deletePaymentTransactionApi = (invoice_account_id, transaction_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/transaction/${transaction_id}/delete`);
}

export const getPendingInvoiceListApi = (invoice_account_id, client_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/pending_invoices`);
}

export const deleteInvoiceApi = (invoice_account_id, invoice_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/delete`);
}

export const getInvoicesReportApi = (invoice_account_id, current_financial_year, previous_financial_year, last_12_months, entity_id) => {
    const param = {
        params: {
            current_financial_year,
            previous_financial_year,
            last_12_months,
            entity_id
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice_report`, param);
}

export const getDueInvoiceReportApi = (invoice_account_id, entity_id) => {
    const param = {
        params: {
            entity_id
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/due_invoice_report`, param);
}

export const updateGeneralSettingsApi = (invoice_account_id, proformaEnabled, hsn_code) => {
    const general_settings = {
        proforma_enabled: proformaEnabled,
        hsn_code_4_digit: hsn_code
    }
    const data = {
        general_settings
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/general_settings`, data);
}

export const  convertToTaxApi = (invoice_account_id, invoice_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/convert_to_tax`);
}

export const addItemApi = (invoice_account_id, title, description, cost, tax_preference, tax, hsn_code) => {
    
    const data = {
        title,
        description,
        cost,
        tax_preference,
        tax,
        hsn_code
    }
    
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/add_item`, data);
}

export const getItemListApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/items`);
}

export const updateItemApi = (invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code) => {
    
    const data = {
        title,
        description,
        cost,
        tax_preference,
        tax,
        hsn_code
    }
    
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/item/${item_id}/update`, data);
}

export const deleteItemApi = (invoice_account_id, item_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/item/${item_id}/delete`);
}

export const verifyInvoiceAccountEmailApi = (invoice_account_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/send_account_verification_email`);
}

export const updateAllAccountUnverifiedEmailStatusApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/update_all_account_unverified_email_status`);
}

export const invoiceMarkAsSentApi = (invoice_account_id, invoice_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/mark_sent`);
}

export const setDueDateApi = (invoice_account_id, invoice_id, due_date) => {
    const data = {due_date}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/set_due_date`, data);
}

export const getClientDetailsApi = (invoice_account_id, client_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/details`);
}

export const getClientInvoicesApi = (invoice_account_id, client_id, page) => {
    const param = {
        params: {
            page
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/invoices`, param);
}

export const getClientTransactionsApi = (invoice_account_id, client_id, page) => {
    const param = {
        params: {
            page
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/transactions`, param);
}

export const getUserDetailsObjApi = () => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/user/me`);
}

export const updateUserProfileApi = (firstname, lastname, image) => {
    
    var data = new FormData();
    data.set('firstname', firstname);
    data.set('lastname', lastname);
    if (image) {
        data.set('image', image);
    }
    
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update`, data);
}

//called from profile page
export const changePasswordApi = (old_password, password) => {
    let data = {
        old_password,
        password
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/change_password`, data);
}

export const createInvoiceAccountApi = (name, email, country, state) => {
    let data = {
        name,
        email,
        country,
        state
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/user/invoice_accounts/create`, data);
}

export const addMemberToInvoiceAcountApi = (service, email, role, entity_id, entity_name) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/invite`, data);
}

export const accountMembersListApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/account_user`);
}

export const changeMemberRoleApi = (invoice_account_id, invoice_account_user_id, role) => {
    let data = {invoice_account_user_id, role}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/account_user`, data);
}

export const deleteAccountMemberApi = (invoice_account_id, id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/account_user/${id}/delete`);
}

export const checkIfUserIsRegisteredApi = (email) => {
    const param = {
        params: {
            email
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/is_registered`, param);
}

export const getPendingInvitationsListApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/pending_invitations`);
}

export const invoiceAccountUpdateApi = (invoice_account_id, name, address, country, state, email, mobile, tax_id,
    default_client_notes, default_payment_terms, image, prefix, sequence_format, financial_year_start_month, currency, onboarding) => {
    var data = new FormData();
    data.set('name', name);
    data.set('address', address);
    data.set('country', country);
    data.set('state', state);
    data.set('email', email);
    data.set('mobile', mobile);
    data.set('tax_id', tax_id);
    data.set('default_client_notes', default_client_notes);
    data.set('default_payment_terms', default_payment_terms);
    if (image) {
        data.set('image', image);
    }
    data.set('prefix', prefix);
    data.set('sequence_format', sequence_format);
    data.set('financial_year_start_month', financial_year_start_month);
    data.set('currency', currency);
    data.set('onboarding', onboarding);
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/user/invoice_accounts/${invoice_account_id}/update`, data);
}

export const resendInvitationApi = (service, email, role, entity_id, entity_name, entity_email) => {
    let data = {
        service,
        email,
        role,
        entity_id, 
        entity_name,
        entity_email
    }
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/resend_invite`, data);
}

export const sendUserVerificationMailApi = () => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/user/send_user_verification_email`);
}

export const verifyEmailApi = (queryParams) => {
    getAxios().defaults.headers.common['Authorization'] = queryParams.jwt;
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/email_verify`);
}

export const logoutApi = () => {
    return getAxios().post(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/logout`);
}

export const pendingInvitationExistApi = (invoice_account_id, email) => {
    const param = {
        params : {
            email
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/pending_invitation_exist`, param);
}

export const createPendingInvitationApi = (invoice_account_id, role, email) => {
    const data = {
        invoice_account_id,
        role,
        email
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/user/invitation/create`, data);
}

export const removeScopeApi = (service, user_id) => {
    let data = {service, user_id}
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/remove_scope`, data);
}

export const getClientUploadURLApi = (client_id, filename, type) => {
    let data = {filename, type}
    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/client/${client_id}/attachment/upload_url`, data);
}

export const uploadCustomFieldSuccessApi = (client_id, path) => {
    let data = {path};
    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/client/${client_id}/attachment/upload_success`, data);
}

export const deleteCustomFieldAttachmentApi = (client_id, path) => {
    let data = {path};
    return getAxios().put(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/client/${client_id}/attachment/delete`, data);
}

export const downloadAttachmentApi = (invoice_account_id, path) => {
    let data = {path};
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/attachment/download_success`, data);
}

export const createDuplicateInvoiceApi = (invoice_account_id, invoice_id) => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/create_duplicate`);
}

export const changeInvoiceCustomerApi = (invoice_account_id, invoice_id, client_id) => {
    let data = {client_id}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/change_customer`, data);
}

export const updateTDSSettingsApi = (invoice_account_id, enable_tds) => {
    let data = {enable_tds}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/tds_settings`, data);
}

export const getTDSTypeListApi = (invoice_account_id, country_code) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/tds_types/${country_code}`);
}

export const addBankAccountApi = (invoice_account_id, entity_id, bank_name, account_holder_name, account_number) => {
    const data = {
        bank_name,
        account_holder_name,
        account_number,
    };

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${entity_id}/bank_account/add`,data);
};


export const deleteBankApi = (invoice_account_id, bank_id) => {
    return getAxios().delete( `${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/bank_account/${bank_id}/delete`);
};

export const updateBankApi = (invoice_account_id, id, bank_name, account_holder_name, account_number) => {
    const data = {
        bank_name,
        account_holder_name,
        account_number
    };

    return getAxios().put( `${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/bank_account/${id}/update`,data);
};

//Get Company List API
export const orgCompaniesListApi = (page, text, organization_id) => {
    const param = {
        params: {
            page,
            text
        }
    }
    return getAxios().get(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/company/${organization_id}`, param);
}

export const saveDefaultTaxPreferenceApi = (invoice_account_id, tax_preference)   => {
    const data = {
        tax_preference
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/tax_preference`, data);
}

export const createInvoiceAccountEntityApi = (invoice_account_id, name, email, country, state) => {
    let data = {
        name,
        email,
        country,
        state
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/create_entity`, data);
}

export const createUpdateInvoiceAccountEntityApi = (invoice_account_id, name, address, country, state, email, mobile, tax_id,
    default_client_notes, default_payment_terms, image, entity_id, upi_id) => {
        console.log('image', image);

    var data = new FormData();
    let api_path = '';
    data.set('name', name);
    data.set('address', address);
    data.set('country', country);
    data.set('state', state);
    data.set('email', email);
    data.set('mobile', mobile);
    data.set('tax_id', tax_id);
    data.set('default_client_notes', default_client_notes);
    data.set('default_payment_terms', default_payment_terms);
    data.set('upi_id', upi_id);
    if (image) {
        data.set('image', image);
    }
    api_path =  entity_id ? getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${entity_id}/update`, data)
    :
    getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/create_entity`, data);
    return api_path
}

export const verifyInvoiceAccountEntityEmailApi = (invoice_account_id, entity_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/send_entity_verification_email`);
}

export const updateAllAccountEntityUnverifiedEmailStatusApi = () => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/entity/update_all_entity_unverified_email_status`);
}


export const uploadESignImageApi = (invoice_account_id, path, type) => {
    let data = {
        path, 
        type
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/attachment/signed_upload_url`, data);
}

export const updateEntityEsignApi = (invoice_account_id, entity_id, signature, enable_esign) => {
    let data = {
        signature, 
        enable_esign
    }

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/update_esign`, data);
}

export const getImageDownloadUrlApi = (invoice_account_id, path, type, md5) => {

    let data = {
        path, 
        type
    }
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/attachment/signed_download_url`, data)

}

export const getInvoiceAccountsEntityListApi = (invoice_account_id, apiPath) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiPath}`);
}

export const updateInvoiceEntityEsignApi = (invoice_account_id, invoice_id, enable_esign) => {
    const data = {enable_esign}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/update_esign`, data);
}

export const enableInvoiceRecurringDateApi = (invoice_account_id, invoice_id, interval_days, send_invoice_mail) => {
    let data = {interval_days, send_invoice_mail}

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/recurring/enable`, data);
}

export const updateInvoiceRecurringDateApi = (invoice_account_id, invoice_id, recurring_invoice_id, interval_days, send_invoice_mail) => {
    let data = {interval_days, send_invoice_mail}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/recurring_invoice/${recurring_invoice_id}/update`, data);
}

export const updateNextIssueDateApi = (invoice_account_id, invoice_id, recurring_invoice_id, next_issue_date) => {
    let data = {next_issue_date}

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/recurring_invoice/${recurring_invoice_id}/update_date`, data);
}

export const disableRecurringInvoiceApi = (invoice_account_id, invoice_id, recurring_invoice_id) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/recurring_invoice/${recurring_invoice_id}/disable`);
}

export const checkBackgroundTaskCompleted = (invoice_account_id, apiRoute, task_status_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/task_status/${apiRoute}/${task_status_id}`);
}

export const downloadTemplateApi = (invoice_account_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/export_clients`);
}

export const importCompanyListApi = (invoice_account_id, excel_file) => {
    var data = new FormData();
    data.set('excel_file', excel_file);
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/import_clients`, data);
}

export const getDueInvoiceListApi = (invoice_account_id, entity_id, days_param, page) => {
    const param = {
        params: {
            entity_id,
            days_param, 
            page
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/get_due_report_invoices`, param);
}

export const updateEntityNextInvoiceNumberApi = (invoice_account_id, next_invoice_number, next_estimate_number, apiStr) => {
    let data = {next_invoice_number, next_estimate_number}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}`, data);
}

export const exportInvoicesApi = (invoice_account_id, issue_start_date, issue_end_date, clients, entities, show_tds, type, status) => {
    const param = {
        params: {
            issue_start_date,
            issue_end_date, 
            clients,
            entities,
            show_tds, 
            type,
            status
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/export_invoices`, param);
}

export const exportTransactionsApi = (invoice_account_id, payment_start_date, payment_end_date, payment_method, clients) => {
    const param = {
        params: {
            payment_start_date,
            payment_end_date, 
            payment_method,
            clients
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/export_transactions`, param);
}

export const updateTcsSettingsApi = (invoice_account_id, tcs) => {
    let data = {tcs}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/tcs_settings`, data);
}

export const getEntityNextInvoiceEstimateNumberApi = (invoice_account_id, apiStr) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}`);
}

export const getNewInvoiceNumberApi = (invoice_account_id, old_issue_date, new_issue_date, invoice_type, apiStr) => {
    const param = {
        params: {
            old_issue_date,
            new_issue_date,
            invoice_type
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}`, param);
}

export const updateInvoicePrintSettingsApi = (invoice_account_id, print_settings) => {
    let data = {print_settings}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update/print_settings`, data);
}

export const downloadInvoicePrintApi = (invoice_account_id, invoice_id, original_print, transport_print, customer_print) => {
    let data = {original_print, transport_print, customer_print}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/download_print`, data);
}

export const authenticateApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/authenticate`, param)
}

export const updateEntitiesDetailsApi = (invoice_account_id, entities_data, apiStr) => {
    const data = {
        entities_data
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}`, data);
}

export const updateUserLoginApi = () => {
    return getAxios().put(`${process.env.REACT_APP_AUTH_APP_BASE_URL}/user/update_user_login`);
}

export const invoiceAccountOnboardingUpdateApi = (invoice_account_id, onboardingValue) => {

    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/onboarding/${onboardingValue}`);
} 

export const downloadReceiptApi = (invoice_account_id, invoice_id, transaction_id, details) => {
    let data = {details}
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/transaction/${transaction_id}/download_receipt`, data);
}

export const createCheckoutSessionApi = (invoice_account_id, lookup_key, quantity, currency) => {
    let data = {invoice_account_id, lookup_key, quantity, currency}

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/stripe/checkout_session_url`, data);
} 

export const createBillingPortalApi = (invoice_account_id, customer_id) => {
    let data = {customer_id}

    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/stripe/customer_portal_url`, data);
} 

export const archiveRestoreDeleteEntityApi = (invoice_account_id, entity_id, apiPath) => {
    let path = apiPath === 'hard_delete_entity' ? getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entities/${entity_id}${apiPath ? `/${apiPath}` : ''}`)
                                    : getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entities/${entity_id}${apiPath ? `/${apiPath}` : ''}`);

    return path;
}

export const getCurrencyObjApi = (invoice_account_id, query_string) => {
    const param = {
        params: {
            query_string
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/get_currency`, param);
}

export const cancelInvoiceApi = (invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id) => {
    let data = {cancel_recurring_invoice, recurring_invoice_id}
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/cancel`, data);
}

export const convertTODraftApi = (invoice_account_id, invoice_id) => {
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/convert_to_draft`);
}

export const enableDisableEmailReminderApi = (invoice_account_id, invoice_id, flag) => {
    let data = { flag }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/enable_disable_reminder`, data);
}

export const getInvoiceTransactionApi = (invoice_account_id, invoice_id) => {

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/invoice/${invoice_id}/transactions`);
}

export const getEntityClientInvoicesReportApi = (invoice_account_id, entity_id, client_name, fromdate, todate) => {
    const param = {
        params: {
            entity_id,
            client_name,
            fromdate,
            todate
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client_statement_report_list`, param);
}

export const printClientInvoiceReportApi = (invoice_account_id, entity_id, client_name, fromdate, todate) => {
    const param = {
        params: {
            entity_id,
            client_name,
            fromdate,
            todate
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/Client_report_list/download`, param);
}

export const getClientInvoiceSummaryApi = (invoice_account_id, client_id, todate) => {
    const param = {
        params: {
            todate
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/statement/report`, param);
}

export const downloadClientSummaryApi = (invoice_account_id, client_id, todate) => {
    const param = {
        params: {
            todate
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/statement/download`, param);
}

export const sendClientStamentApi = (invoice_account_id, client_id, email_array, subject, body, todate) => {
        const data = {
            email_array,
            subject,
            body,
            todate
        }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/send-client_statement`, data);
}

export const downloadCsvForRevenueByClientAndItemApi = (invoice_account_id, apiStr, fromdate, todate, client_name, item_name) => {
    const param = {
        params: {
            todate,
            fromdate,
            client_name,
            item_name
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiStr}/csv/download`, param);
}

export const downloadCsvForClientSummaryApi = (invoice_account_id, client_id, todate) => {
    const param = {
        params: {
            todate,
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/client/${client_id}/client_statement/csv/download`, param);
}

export const getRevenueByItemsApi = (invoice_account_id, entity_id, item_name, fromdate, todate) => {
    const param = {
        params: {
            entity_id,
            item_name,
            fromdate,
            todate
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/item/revenue_by_items`, param);
}

export const downloadRevenueByItemsReportApi = (invoice_account_id, entity_id, item_name, fromdate, todate) => {
    const param = {
        params: {
            entity_id,
            item_name,
            fromdate,
            todate
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/items/revenue_by_items/pdf/download`, param);
}

export const createEstimateApi = (invoice_account_id, entity_id, client_id, email_array, estimate_description, subtotal,
    total_tax, total, issue_date, client_notes, payment_terms, items_details, show_client_notes, show_payment_terms, download, 
    discount, set_number_manually,  estimate_number, reference_number, 
    currency_id) => {
    const data = {
        email_array,
        estimate_description, 
        subtotal, 
        total_tax, 
        total, 
        issue_date, 
        client_notes, 
        payment_terms, 
        items_details,
        show_client_notes,
        show_payment_terms,
        download,
        discount,
        set_number_manually, 
        estimate_number,
        reference_number,
        currency_id
    }
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/client/${client_id}/estimate/create`, data);
}

export const getEstimateListApi = (invoice_account_id, page, entity_ids, issue_start_date, issue_end_date, clients, status, bill_status, order_by) => {

    const param = {
        params: {
            page,
            entity_ids,
            issue_start_date,
            issue_end_date,
            status,
            clients,
            bill_status,
            order_by
        }
    }

    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/all`, param);
}

export const editEstimateApi = (invoice_account_id, estimate_id, email_array, estimate_description, subtotal, total_tax, total, issue_date, 
    client_notes, payment_terms, items_details, show_client_notes, show_payment_terms, download, discount, set_number_manually,  estimate_number, 
    reference_number, currency_id, client_id, entity_id) => {
    const data = {
        email_array,
        estimate_description, 
        subtotal, 
        total_tax, 
        total, 
        issue_date, 
        client_notes, 
        payment_terms, 
        items_details,
        show_client_notes,
        show_payment_terms,
        download,
        discount,
        set_number_manually, 
        estimate_number,
        reference_number,
        currency_id,
        client_id, 
        entity_id
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/update`, data);
}

export const updateEstimateSettingsApi = (invoice_account_id, estimate_approval_required, estimate_terminology) => {
    const data = {
            estimate_approval_required,
            estimate_terminology
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/update_estimate_settings`, data);
}

export const getEstimateInvoiceDetailsApi = (invoice_account_id, apiPath) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/${apiPath}/get`);
}

export const deleteEstimateApi = (invoice_account_id, estimate_id) => {
    return getAxios().delete(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/delete`);
}

export const downloadEstimateApi = (invoice_account_id, entity_id, client_id, estimate_id) => {
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/client/${client_id}/estimate/${estimate_id}/download`);
}

export const cancelReopenCloseEstimateApi = (invoice_account_id, estimate_id, apiStr) => {
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/${apiStr}`);
}

export const createDuplicateEstimateApi = (invoice_account_id, entity_id, client_id, estimate_id) => {
    return getAxios().post(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/client/${client_id}/estimate/${estimate_id}/create_duplicate`);
}

export const sendEstimateEmailApi = (invoice_account_id, entity_id, estimate_id, email_array, subject, body) => {
    const data = {
        email_array,
        subject,
        body
    }
    return getAxios().put(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/entity/${entity_id}/estimate/${estimate_id}/send-estimate`, data);
}

export const getClientEstimatePreviewObjApi = (md5, estimate_uuid, email) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/client/estimate/${estimate_uuid}/preview?email=${email}`);
}

export const approveRejectEstimateApi = (invoice_account_id, md5, estimate_id, apiStr) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/${apiStr}`);
}

export const downloadClientEstimateApi = (invoice_account_id, md5, estimate_id) => {
    getAxios().defaults.headers.common['Challenge'] = md5;
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/download`);
}

export const exportEstimatesApi = (invoice_account_id, issue_start_date, issue_end_date, clients, entities, status, bill_status) => {
    const param = {
        params: {
            issue_start_date,
            issue_end_date, 
            clients,
            entities,
            status,
            bill_status
        }
    }
    return getAxios().get(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/export_estimates`, param);
}

export const markAsApprovedApi = (invoice_account_id, estimate_id, apiStr) => {
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/${apiStr}`);
}

export const estimateMarkAsSentApi = (invoice_account_id, estimate_id) => {
    return getAxios().patch(`${process.env.REACT_APP_BASE_URL}/invoice_account/${invoice_account_id}/estimate/${estimate_id}/mark_sent`);
}