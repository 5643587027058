import React, {useEffect, useState, useRef, createRef} from 'react';
import { makeStyles, Select, Tooltip } from '@material-ui/core';
import {Grid, Typography, Avatar, Button, TextField, InputAdornment,
    Container, CircularProgress, Link, Radio, RadioGroup, FormControlLabel, FormControl, Popover, MenuItem} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import {connect} from 'react-redux';
import {setCreateInvoiceAccount, setInvoiceItemObj, setCustomerObject, 
    setShowPreviewInvoiceScreen, setShowSendInvoiceScreenType, setEditInvoiceObj, SendEmailType} from '../actions/invoiceAccount';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {approveRejectEstimateApi, sendInvoiceEmailApi} from '../services/authService';
import { useSnackbar } from 'notistack';
import {consoleToLog, emailValidator, showDate, parseString, showStatusImages, displayInitials, displayInvoiceType, trackGAEvent, DiscountTypes, invoiceAccountCountryObj, EstimateStatus} from '../util/AppUtil';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState,  RichUtils, Modifier } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {getMentionOptionsForSubject ,getMentionOptionsForBody} from '../services/mentionService';
import { useIsMount } from './useIsMount';
import { MentionsInput, Mention } from 'react-mentions';
import AddEditPaymentDrawer from './AddEditPaymentDrawer';
import {loggedInUserDownloadApi, invoiceMarkAsSentApi, setDueDateApi} from '../services/authService';
import moment from 'moment';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import {AccessLevel, currencyFormatter} from '../util/AppUtil';
import DatePicker from 'react-datepicker';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddEditClientDrawer from './AddEditClientDrawer';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { useReactToPrint } from 'react-to-print';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import {updateEntityEsignApi} from '../services/authService';
import EnableInvoiceESignModal from './modals/EnableInvoiceESignModal';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import {getImageDownloadUrlApi, updateInvoiceEntityEsignApi} from '../services/authService';
import RecurringInvoiceSettings from './RecurringInvoiceSettings';
import AddEditBankDrawer from './AddEditBankDrawer';
import { InvoiceStatus } from '../util/AppUtil';
import printJS from 'print-js';
import PrintInvoiceModal from './modals/PrintInvoiceModal';
import {formatAmount} from '../util/currencyUtil';
import { getUrlAccordingToSelectedComponent } from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import {isInvoiceStatusCancelled} from '../util/AppUtil';
import {enableDisableEmailReminderApi} from '../services/authService';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PaymentReceipt from './PaymentReceipt';
import {getInvoiceTransactions} from '../util/invoiceTransactionUtil';
import EnableDisableReminders from './EnableDisableReminders';
import SendEmailComponent from './SendEmailComponent';
import { setShowEstimateScreen } from '../actions/invoiceAccount';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { setEntityObj } from '../actions/invoiceAccount';
import { showValidityMessage } from '../util/AppUtil';
import { downloadEstimateApi } from '../services/authService';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ShowConfirmDialog from './ShowConfirmDialog';
import CloseIcon from '@material-ui/icons/Close';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { cancelReopenCloseEstimateApi, sendEstimateEmailApi, markAsApprovedApi } from '../services/authService';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import {estimateStatusImage} from '../util/AppUtil';
import {cancelInvoiceApi, estimateMarkAsSentApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: "16px",
        marginBottom:'32px',
        padding:'8px 8px 8px 16px'
    },
    createFormContainer : {
        background:'white',
        padding:'0px 0px 32px 0px',
        borderRadius:'5px',
        '& .customColumn1' : {
        flexBasis:'58%',
        maxWidth:'58%'
        }

    },
    dialogTitle : {
        borderBottom:'1px solid #ccc', 
        padding:'16px 16px', 
        display:'flex',  
        justifyContent:'space-between', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight:'600'
    },
    firstRow: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'10px 0px 8px 0px'
    },
    backIconContainer : {
        [theme.breakpoints.down(1450)] : {
            flexBasis:'50%',
            maxWidth:'50%'
        }
    },
    backIcon: {
        padding:'6px',
        border:'1px solid rgba(0,0,0, .1)',
        borderRadius:'5px',
        cursor:'pointer',
        marginLeft:'16px'
    },
    secondRow: {
        padding:'24px' 
    },
    avatarStyle: {
        width:"80px",
        height:"80px",
        fontSize:'35px',
        fontWeight:'600'
    },
    thirdRow: {
        padding:'16px 24px 16px 24px' 
    },
    customColumn: {
        marginLeft:'16px',
        flexBasis:'40%',
        maxWidth:'40%',
    },
    secondColumnContainer: {
        background:'#fff',
        borderRadius: '4px',
        padding:'0px 0px 32px 0px',
    },
    buttonStyles: {
        marginBottom:'8px',
    },
    plusIcon: {
        fontSize: '18px',
        marginRight:'8px'
    },
    MuiTextFieldroot : {
        background:'#fff',
        fontSize:'12px !important'
    },
    highlightOffIcon: {
        fontSize:'16px',
        marginRight:'4px'
    },
    customMarginBottom : {
        marginBottom: '24px'
    },
    MuiFormControlLabelroot : {
        '& .MuiFormControlLabel-label' : {
            fontSize: '14px',
            color: '#555555',
            marginLeft:'-4px'
        }
    },
    customColumn2: {
        marginLeft:'16px',
        flexBasis:'23%',
        maxWidth:'23%'
    },
    paymentClientStyles: {
        marginLeft:'8px'
    },
    clientOwnerName: {
        color: '#4a5568',
        fontSize: '15px',
        fontWeight: 600,
    },
    firstRow1: {
        borderBottom:'1px solid rgba(0,0,0, .1)',
        padding:'10px 16px 8px 0px'
    },
    customColumn3: {
        flexBasis:'12%',
        maxWidth:'12%'
    },
    customColumn4: {
        flexBasis:'16%',
        maxWidth:'16%'
    },
    customColumn5: {
        flexBasis:'22%',
        maxWidth:'22%'
    },
    customColumn6: {
        flexBasis:'38%',
        maxWidth:'38%'
    },
    moreHorizIconStyle: {
        marginLeft:'8px', 
        color:'#4a5568',
        cursor:'pointer'
    },
    moreActionIconStyle: {
        color: '#b5bbc2',
        marginRight:'6px'
    },
    customMarginLeft: {
        marginLeft:'auto'
    },
    customTextStyle: {
        fontSize:'13px', 
        color:'#4a5568', 
        marginTop:'1px',
    },
    typeStyle : {
        fontSize:'13px', 
        color:'#4a5568', 
        marginTop:'1px',
        marginLeft:'6px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
    },
    paper: {
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        minWidth:'256px'
    },
    moreOptionsStyles: {
        padding:"12px",
        fontSize:"15px"
    },
    tdsDisplay: {
        width:'auto',
        maxWidth:'215px', 
        overflow:'hidden',
        whiteSpace:'nowrap', 
        textOverflow:'ellipsis',
        [theme.breakpoints.down(1450)] : {
            width:'170px', 
        }
    },
    verifyLink: {
        color: '#0000EE',
        cursor: 'pointer',
        fontSize:'12px',
        margin:"0px 1px 0px 8px",
        '&:hover': {
            textDecoration:'underline'
        }
    },
}));

const PreviewAndSendInvoiceComponent = (props) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [emailArr, setEmailArr] = useState([]);
//     const [message, setMessage] = useState(`Dear ${props.invoiceObj?.client.name}, 

// Thank you for your business, always a pleasure to work with you! 

// We have generated a new invoice in the amount of ${props.invoiceObj?.due.toFixed(2)}₹

// We would appreciate payment of this invoice by ${props.invoiceObj?.issue_date}`);

    const [sendEmail, setSendEmail] = useState(false);
    const [errorType, setErrorType] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [emailArrIndex, setEmailArrIndex] = useState(emailArr.length - 1);
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [mentionOptionsForBody, setMentionOptionsForBody] = useState([]);
    const [mentionOptionsForSubject, setMentionOptionsForSubject] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [mentions, setMentions] = useState();
    const [openAddPaymentDrawer, setOpenAddPamentDrawer] = useState(false);

    const [dueDateOptionValue, setDueDateOptionValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [customDate, setCustomDate] = useState(moment());
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [editClientObj, setEditClientObj] = useState(undefined);
    const [showMoreOptions, setShowMoreOptions] = useState(null);

    //store edit invoice object in variable

    const [clientName, setClientName] = useState(props.invoiceObj?.client.name);
    const [clientAddress, setClientAddress] = useState(props.invoiceObj.client?.address);
    const [clientCountry, setClientCountry] = useState(props.invoiceObj.client?.country);
    const [clientState, setClientState] = useState(props.invoiceObj.client?.state);
    const [clientMobile, setClientMobile] = useState(props.invoiceObj.client?.phone_number);
    const [clientEmail, setClientEmail] = useState('');
    const [clientContacts, setClientContact] = useState(props.invoiceObj.client?.contacts);
    const [clientUserDefinedFields, setClientUserDefinedField] = useState(props.invoiceObj.client?.user_defined_field);
    const [openESignDialog, setOpenESignDialog] = useState(false);
    const [selectedEntityObj, setSelectedEntityObj] = useState(undefined);
    const [eSignUrl, setESignUrl] = useState(undefined);
    const [downloadInvoiceURL, setDownloadInvoiceURL] = useState(undefined);

    const [openClientDrawer, setOpenClientDrawer] = useState(false);

    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [bankAccObj, setBankAccObj] = useState(undefined);
    const [entityObjForBankAcc, setEntityObjForBankAcc] = useState(false);
    const [openPrintModal, setOpenPrintModal] = useState(false);
    const [invoiceTransactions, setInvoiceTransactions] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);

    const [openComfirmDialog, setOpenConfirmDialog] = useState(false);
    const myDivToFocus = createRef();
    const contentToPrintRef = useRef(null);

    useEffect(async() => {
        if(!props.showEstimateScreen) {
            const data = await getInvoiceTransactions(invoice_account_id, invoice_id, props);
            setInvoiceTransactions(data);
        }
    }, [])

    // useEffect(async() => {
    //     if(!isMount) {
    //         const data = await getInvoiceTransactions(invoice_account_id, invoice_id, props);
    //         setInvoiceTransactions(data);
    //     }
    // }, [props.invoiceObj])

    useEffect(() => {
        if(myDivToFocus.current) {
            myDivToFocus.current.scrollIntoView({
                scrollY:'0px'
            })
        }
    }, [myDivToFocus.current]);

    useEffect(() => {
        if(!isMount) {
            if(downloadInvoiceURL) {
                printJS({printable: downloadInvoiceURL, type:'pdf', showModal:true});
                setDownloadInvoiceURL(undefined);
            }
        }
    }, [downloadInvoiceURL]);

    useEffect(() => {
            document.addEventListener("keydown", handleKeydown, false);
        
            return () => {
                document.removeEventListener("keydown", handleKeydown);
            };
    }, []);

    useEffect(() => {
        prefillFormData();
        setMentionOptionsForBody(getMentionOptionsForBody())
        setMentionOptionsForSubject(getMentionOptionsForSubject())
        calculateDateDiff();
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        if(isMount) {
            setEmailArrIndex(props.invoiceObj?.email_array.length - 1);
            // const updatedArray = emailArr;
            
            // updatedArray[emailArrIndex + 1] = {email: client_email};
            const updatedArray = props.invoiceObj?.email_array.map((item, i) => {
                return emailArr[i] = {email: item}
            })
            setEmailArr([...updatedArray]);
        }
    }, []);

    useEffect(() => {
        if(!isMount) {
            setEmailArrIndex(emailArr.length - 1);
           // showSendEmailUI()
        }

    }, [emailArr]);

    useEffect(() => {
        if(props.invoiceObj?.entity) {
            const selectedObj = entities.find((entity) => entity.id === props.invoiceObj?.entity.id);
            setSelectedEntityObj(selectedObj);
        }
    }, [props.invoiceObj?.entity]);

    useEffect(() => {
        if(!isMount) {
            if(selectedEntityObj) {
                if(selectedEntityObj?.enable_esign) {
                    getDownloadSignedUrl(selectedEntityObj);
                }
            }
        }
    }, [selectedEntityObj]);

    let over_due = (moment(props.invoiceObj.due_date).toDate().getTime() < Date.now());
    const invoiceType = props.invoiceObj?.invoice_type;
    const entityObj = props.invoiceObj?.entity;

    const is_email_verified = entityObj?.is_email_verified;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const entities = props.selectedAccount?.entities;
    const recurring_invoice_obj = props.invoiceObj.recurring_invoice;
    const invoice_account_id = props.selectedAccount?.id;
    const invoice_id = props.invoiceObj?.id;

    const number = !props.showEstimateScreen ? props.invoiceObj?.invoice_number : props.invoiceObj?.estimates_number;
    const sequence_str = !props.showEstimateScreen ? props.invoiceObj?.invoice_sequence_str : props.invoiceObj?.estimate_sequence_str;
    const description = !props.showEstimateScreen ? props.invoiceObj?.invoice_description : props.invoiceObj?.estimate_description;
    const label = !props.showEstimateScreen ? 'Invoice' : props.selectedAccount?.estimate_terminology;
    const items = !props.showEstimateScreen ? props.invoiceObj?.invoice_items : props.invoiceObj?.estimate_items;
    const status = props.invoiceObj?.status;

    
    const currencyObj = props.showEstimateScreen ? props.invoiceObj?.currency : props.invoiceObj?.currencies;
    const reminderObj = props.invoiceObj?.invoice_reminders;
    const showSendEmail = (props.showSendInvoiceScreenType === SendEmailType.TAX || props.showSendInvoiceScreenType === SendEmailType.PROFORMA || props.showSendInvoiceScreenType === SendEmailType.ESTIMATE);


    const open = Boolean(anchorEl);
    const id = open ? 'custom-date-popover' : undefined;

    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'edit-client' : undefined;

    const client_name = props.invoiceObj?.client.name;
    const invoiceObj = props.invoiceObj;

    const handleKeydown = (e) => {
            if(e.ctrlKey && e.key === 'p') {
                e.preventDefault();
                handlePrintInvoiceModalOpen();
            }
    }


    // const handleCustomDatePopoverClick = () => {
    //     setAnchorEl(popoverRef.current);
    // };

    const handleCustomDatePopoverClose = () => {
            setAnchorEl(null);
    }

    const handleEditClinetPopoverClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleEditClinetPopoverClose = () => {
        setAnchorEl1(null);
    }


    // const handleCustomDatePopOver = () => {
    //     return (<Popover
    //         id={id}
    //         open={open}
    //         anchorEl={popoverRef}
    //         onClose={handleCustomDatePopoverClose}
    //         anchorOrigin={{
    //             vertical: "bottom",
    //             horizontal: "right"
    //         }}
    //         className='date_popover_style_one'
    //     >
    //         <DatePicker
    //             selected={moment(customDate).toDate()}
    //             onChange={onCustomDateChange}
    //             //selectsRange
    //             inline
    //         />
    //     </Popover>)
    // }

    const handleEditClientDrawerOpen = () => {
        setOpenClientDrawer(true);
        setEditClientObj(props.invoiceObj?.client);
        setAnchorEl1(null);
    }

    const handleEditClientDrawerClose = () => {
        setOpenClientDrawer(false);
        setEditClientObj(undefined);
    }

    const showEditClientPopOver = () => {
        return (<Popover
            id={id1}
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleEditClinetPopoverClose}
            style={{marginTop:'18px'}}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
            }}
        >
            <MenuItem 
                onClick={handleEditClientDrawerOpen}> 
                <EditOutlinedIcon fontSize='small' className={classes.moreActionIconStyle}/>
                Edit
            </MenuItem>
        </Popover>)
    }

    // console.log('index', emailArrIndex);

    // useEffect(() => {
    //     if(!isMount) {
    //         if(dueDateOptionValue === 'custom') {
    //             handleCustomDatePopoverClick();
    //         }
    //     }
    // }, [dueDateOptionValue]);

    const handlePrintInvoiceModalOpen = () => {
        setOpenPrintModal(true);
    }

    const handlePrintInvoiceModalClose = () => {
        setOpenPrintModal(false);
    }

    // const handlePrint = useReactToPrint({
    //     documentTitle: "Print This Document",
    //     onBeforePrint: () => console.log("before printing..."),
    //     onAfterPrint: () => console.log("after printing..."),
    //     removeAfterPrint: true,
    // });

    const calculateDateDiff = () => {
        const issueDate = moment(props.invoiceObj.issue_date)
        const dueDate = moment(props.invoiceObj.due_date)
        const result = dueDate.diff(issueDate, 'days');
        //console.log('result*******', result);
        if(result === 0) {
            setDueDateOptionValue('0');
        } else if(result === 7) {
            setDueDateOptionValue('7');
        } else if(result === 14) {
            setDueDateOptionValue('14');
        } else if(result === 30) {
            setDueDateOptionValue('30');
        } else {
            setDueDateOptionValue('custom');
        }
    }

    const prefillFormData = () => {
        const { body, subject } = props.invoiceObj?.email_json ? props.invoiceObj?.email_json : {};

        if(body) {
            const blocksFromHtml = htmlToDraft(body);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }

        
        setEmailSubject(subject);
    }

    const removeFromEmailArr = (index) => {
        //emailArr.splice(emailArrIndex, 1);
        if(index > -1) {
            emailArr.splice(index, 1)
            console.log('removed item', emailArr);
            setEmailArr([...emailArr]);
            
            setErrorType(0);
            setErrorMessage('');
            //getTaxDetails();
        }
    }

    const removeLabel = (index) => {
        return {
            endAdornment: 
                <InputAdornment position="end" className={classes.daysLabel}>
                    <Button style={{fontSize:'12px'}}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFromEmailArr(index)
                        }}
                    >
                        <HighlightOffIcon 
                        className={classes.highlightOffIcon}
                        fontSize='small'/> Remove
                    </Button>
                </InputAdornment>
        }
    }

    //console.log('api response', props.invoiceObj);

    const onEmailFieldChange = (e, index) => {
        const updatedArray = emailArr;
        updatedArray[index].email = e.target.value;
        const isEmailValid = emailValidator(updatedArray[index].email);
        setEmailArr([...updatedArray]);
    }

    const validateEmail = (index) => {
        if (emailArr[index].email !== '' && !emailValidator(emailArr[index].email)) {
            return 'The email field must be an valid email.';
        } else {
            return true;
        }
    }

    const validateEmail1 = (index) => {
        const updatedEmailArr = emailArr.filter((email, index) => validateEmail(index) !== true);
        return updatedEmailArr && updatedEmailArr.length > 0 ? true : false;
    }


    const onSendEmailClickFromPreviewInvoice = (email_array, email_json) => {
        setErrorType(0);
        setErrorMessage('');
        //const email_array = emailArr.map((item) => item.email);
        //console.log('email_array', email_array);

        const invoice_id = props.invoiceObj?.id;

        // let subject = emailSubject; 
        // if(!subject){
        //     enqueueSnackbar('Subject cannot be empty', {variant:'error'});
        //     return;
        // }

        // let body;
        // if (!editorState.getCurrentContent().hasText()) {
        //     enqueueSnackbar('Email body can not be empty!', {
        //     variant:'error'
        // });
        // return;
        // }
        // body = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // consoleToLog('body: ', body);


        const invoice_type = props.showSendInvoiceScreenType

        setLoading(true);
        sendInvoiceEmailApi(invoice_id, email_array, email_json, invoice_type)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response sendInvoiceEmailApi: ', res);
            setLoading(false);
            enqueueSnackbar('Mail Sucessfully sent', {variant:'success'});

            props.setInvoiceItemObj(res);
            if(props.drawerState === SelectedDrawerState.SHOW_INVOICES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                props.setUpdatedInvoiceObj(res);
            } 

            trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Email Sent', `${props.user.firstname} ${props.user.lastname}`);

        })
        .catch((e) => {
            consoleToLog('Error sendInvoiceEmailApi: ', e);
            setLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {
                    variant:"error"
                });
                return false;
            }
        })

    }

    const onSendIconClick = () => {
        let type = props.showEstimateScreen ? SendEmailType.ESTIMATE : props.invoiceObj?.invoice_type !== SendEmailType.NONE ? props.invoiceObj?.invoice_type : SendEmailType.TAX;
        props.setShowSendInvoiceScreenType(type);
        props.setShowPreviewInvoiceScreen(false);
    }

    const onBackIconClick = () => {
        props.setShowPreviewInvoiceScreen(true);
        props.setShowSendInvoiceScreenType(SendEmailType.NONE);
    }

    const onEditButtonClick = () => {
        let obj = {...props.invoiceObj}
        if(Object.keys(props.editInvoiceObj).length === 0) {
            props.setEditInvoiceObj(props.invoiceObj);
            props.setInvoiceItemObj({})
        }
        trackGAEvent(props.selectedAccount.name, props.showEstimateScreen ?  'Estimate Preview Screen - Edit button clicked' : 'Invoice Preview Screen - Edit button clicked', 
            `${props.user.firstname} ${props.user.lastname}`);
        props.setCreateInvoiceAccount(false);
        props.setCustomerObject({});
        props.setShowPreviewInvoiceScreen(false);
        props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        history.push(props.showEstimateScreen ? `/estimate/${obj?.slug}/edit` : `/invoice/${obj?.slug}/edit`);
    }

    const handleOpenAddPaymentDrawer = (e, invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Add Payment button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setOpenAddPamentDrawer(true);
        handleMoreOptionsPopoverClose();
    };

    const handleCloseAddPaymentDrawer = () => {
        setOpenAddPamentDrawer(false);
    };

    const onSendProformaClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Send Proforma button clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setShowSendInvoiceScreenType(SendEmailType.PROFORMA);
        props.setShowPreviewInvoiceScreen(false);
    }

    const onSendTaxInvoiceClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Send Tax button clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setShowSendInvoiceScreenType(SendEmailType.TAX);
        props.setShowPreviewInvoiceScreen(false);
    }

    const onCustomizeEmailSettingsClick = () => {
        clearAllValues();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
        history.push('/dashboard',  {gotToEmailSettings:true})
    }
    

    const onDownloadInvoiceClick = (fromPrint) => {
        loggedInUserDownloadApi(invoice_account_id, invoice_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response loggedInUserDownloadApi: ', res);

                const downloadableURL = res.document_signed_url;

                if(fromPrint) {
                    setDownloadInvoiceURL(downloadableURL);
                } else {

                    // //initiate download
                    document.getElementById('invoice_download').href = downloadableURL;
                    document.getElementById('invoice_download').click();
                }

                trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Invoice downloaded', `${props.user.firstname} ${props.user.lastname}`);
            
                handleMoreOptionsPopoverClose();
            })
            .catch((e) => {
                consoleToLog('Error loggedInUserDownloadApi: ', e);
                handleMoreOptionsPopoverClose();
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onDownloadEstimateClick = async (fromPrint) => {
        const estimate_id = props.invoiceObj.id;
        const entity_id = props.invoiceObj.entity.id;
        const client_id = props.invoiceObj.client.id;

        try {
            const response = await downloadEstimateApi(invoice_account_id, entity_id, client_id, estimate_id);
            const downloadUrl = response.data;
            consoleToLog('Response downloadEstimateApi: ', downloadUrl);

            const downloadableURL = downloadUrl;

            if(fromPrint) {
                setDownloadInvoiceURL(downloadableURL);
            } else {

                // //initiate download
                document.getElementById('estimate_download').href = downloadableURL;
                document.getElementById('estimate_download').click();
            }
        } catch (error) {
            consoleToLog('Error downloadEstimateApi: ', error);
            if (error.response && error.response.data && error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {variant: 'error'});
            } 
        }
        handleMoreOptionsPopoverClose();
    };

    const onMarkAsSentClick = () => {
        handleMoreOptionsPopoverClose();

        setApiLoading(true);
        if(props.showEstimateScreen) {
            callEstimateMarkAsSentApi();
        } else {
            callInvoiceMarkAsSentApi();
        }

    }

    const callInvoiceMarkAsSentApi = async() => {

        try {
            const response = await invoiceMarkAsSentApi(invoice_account_id, invoice_id);
            const res = response.data;
            consoleToLog('Response invoiceMarkAsSentApi: ', res);
            enqueueSnackbar('Invoice marked as sent', {variant:'success'});
            setApiLoading(false);

            props.setInvoiceItemObj(res);
            if(props.drawerState === SelectedDrawerState.SHOW_INVOICES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) { 
                props.setUpdatedInvoiceObj(res);
            }
        } catch(e) {
            consoleToLog('Error invoiceMarkAsSentApi: ', e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    const callEstimateMarkAsSentApi = async() => {

        try {
            const response = await estimateMarkAsSentApi(invoice_account_id, invoice_id);
            const res = response.data;
            consoleToLog('Response estimateMarkAsSentApi: ', res);
            enqueueSnackbar('Estimate marked as sent', {variant:'success'});
            setApiLoading(false);
            props.setInvoiceItemObj(res);
            if(props.drawerState === SelectedDrawerState.SHOW_ESTIMATES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) { 
                props.setUpdatedInvoiceObj(res);
            }

        } catch(e) {
            consoleToLog('Error estimateMarkAsSentApi: ', e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    const handleDueDateOptionChange = (event) => {
       // console.log('due date value *********', event.target.value);
        setDueDateOptionValue(event.target.value);
        if(event.target.value !== 'custom') {
            updateInvoiceDueDate(event.target.value, '')
        }
    };

    const onCustomDateChange = (dates) => {
        setCustomDate(dates);
        setAnchorEl(null);
        let customDate = moment(dates).format('YYYY-MM-DD'); 
        updateInvoiceDueDate('', customDate)
    }


    const updateInvoiceDueDate = (value, customDate) => {
        const due_date = value ? moment(props.invoiceObj.issue_date).add(Number(value),'days').format('YYYY-MM-DD') : customDate;
        console.log('dueDate*****', due_date);

        setDueDateApi(invoice_account_id, invoice_id, due_date)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response setDueDateApi: ', res);
                enqueueSnackbar('Invoice due date updated', {variant:'success'});

                props.setInvoiceItemObj(res);
            })
            .catch((e) => {
                consoleToLog('Error setDueDateApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const handleMoreOptionsPopoverOpen = (e) => {
        setShowMoreOptions(e.currentTarget);
    }

    const handleMoreOptionsPopoverClose = (e) => {
        setShowMoreOptions(null);
    }

    const handleBankDrawerOpen = () => {
        setOpenBankDrawer(true);
    };

    const handleBankDrawerClose = () => {
        setOpenBankDrawer(false);
    };

    const handleConfirmDialogOpen = (e, obj) => {
        e.stopPropagation();
        setOpenConfirmDialog(true);
        let itemObj = {
            ...obj,
            ...props.invoiceObj
        }
        props.setInvoiceItemObj(itemObj);
        handleMoreOptionsPopoverClose();
    }

    const handleConfirmDialogClose = () => {
        setOpenConfirmDialog(false);
    }

    const callApiForActionClicked = async (estimate_id, apiStr) => {

        setApiLoading(true);

        try {
            const response = await cancelReopenCloseEstimateApi(invoice_account_id, estimate_id, apiStr);
            
            consoleToLog("***Response cancelEstimateApi: ", response.data);
            const res = response.data;
            props.setInvoiceItemObj(res);
            props.setUpdatedInvoiceObj(res);
            enqueueSnackbar(`${props.selectedAccount?.estimate_terminology} Item updated successfully`, { variant: 'success'});
            setApiLoading(false);

            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelEstimateApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }

    const callCancelInvoiceApi = async (invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id) => {
        setApiLoading(true);
        try {
            const response = await cancelInvoiceApi(invoice_account_id, invoice_id, cancel_recurring_invoice, recurring_invoice_id);
            
            consoleToLog("***Response cancelInvoiceApi: ", response.data);
            const res = response.data;
            props.setInvoiceItemObj(res);
            props.setUpdatedInvoiceObj(res);
            enqueueSnackbar('Invoice updated successfully', { variant: 'success'});
            setApiLoading(false);
            handleConfirmDialogClose();
            
        } catch (e) {
            consoleToLog("Err: cancelInvoiceApi", e);
            setApiLoading(false);
            if (e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, { variant: 'error' });
            }
        }
    }

    const onMarkAsApprovedOrRejectedClick = async(apiStr) => {
        handleMoreOptionsPopoverClose();
        setApiLoading(true);
        try {
            const response = await markAsApprovedApi(invoice_account_id, props.invoiceObj.id, apiStr)
            const res = response.data;
            consoleToLog('Response markAsApprovedApi: ', res);
            enqueueSnackbar(res.message, {variant:'success'});
            setApiLoading(false);

            props.setInvoiceItemObj(res);
            if(props.drawerState === SelectedDrawerState.SHOW_ESTIMATES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) { 
                props.setUpdatedInvoiceObj(res);
            }

            handleConfirmDialogClose();
        } catch(e) {
            consoleToLog('Error markAsApprovedApi: ', e.response);
            setApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        }
    }

    const showMoreOptionsPopover = () => {
        return (
        <Popover
            id={'more-options-popover'}
            open={Boolean(showMoreOptions)}
            anchorEl={showMoreOptions}
            onClose={handleMoreOptionsPopoverClose}
            style={{marginTop:'40px', width:'250px'}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.paper,
            }}
        >
            <MenuItem className={classes.moreOptionsStyles}
                onClick={() => props.showEstimateScreen ? onDownloadEstimateClick(false) : onDownloadInvoiceClick(false)}> 
                    <PictureAsPdfIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                        Download
                    <Link
                        style={{ display: "none" }}
                        id={props.showEstimateScreen ? "estimate_download" : "invoice_download"}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        download
                    ></Link>
            </MenuItem>

            <Divider />

            {props.invoiceObj?.can_cancel &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, props.showEstimateScreen ? {cancel_estimate_clicked: true} : {cancel_invoice_clicked: true})}
                className={classes.moreOptionsStyles}> 
                <CancelOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', color:'red', fontSize:'16px'}} />
                    Cancel
            </MenuItem>}

            {props.invoiceObj?.can_cancel && <Divider />}

            {props.showEstimateScreen && props.invoiceObj?.can_close &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {close_estimate_clicked: true})}
                className={classes.moreOptionsStyles}> 
                <CloseIcon fontSize='small'
                    style={{marginRight:'5px', color:'red', fontSize:'16px'}} />
                    Close
            </MenuItem>}

            {props.showEstimateScreen && props.invoiceObj?.can_close && <Divider />}

            {props.showEstimateScreen && props.invoiceObj?.can_reopen &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {reopen_estimate_clicked: true})}
                className={classes.moreOptionsStyles}> 
                <UndoOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                    Reopen
            </MenuItem>}

            {props.showEstimateScreen && props.invoiceObj?.can_reopen && <Divider />}

            {props.invoiceObj.status !== 'paid' && props.invoiceObj.status !== 'draft' && !props.showEstimateScreen &&
            <MenuItem onClick={handleOpenAddPaymentDrawer}
                className={classes.moreOptionsStyles}> 
                <MonetizationOnOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                    Add Payment
            </MenuItem>}

            {props.invoiceObj.status !== 'paid' && props.invoiceObj.status !== 'draft' && !props.showEstimateScreen && <Divider />}

            {props.invoiceObj.status === 'draft' &&
            <MenuItem onClick={onMarkAsSentClick}
                className={classes.moreOptionsStyles}> 
                <CheckIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Sent
            </MenuItem>}

            {props.invoiceObj.approval_required &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {mark_as_approved: true})}
                className={classes.moreOptionsStyles}> 
                <PlaylistAddCheckOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Approved
            </MenuItem>}

            {props.invoiceObj.approval_required && <Divider />}

            {props.invoiceObj.approval_required &&
            <MenuItem onClick={(e) => handleConfirmDialogOpen(e, {mark_as_rejected: true})}
                className={classes.moreOptionsStyles}> 
                <CancelOutlinedIcon fontSize='small'
                    style={{marginRight:'5px', fontSize:'16px'}} />
                    Mark as Rejected
            </MenuItem>}

            {props.invoiceObj.approval_required && <Divider />}

            {props.invoiceObj.status === 'draft' && <Divider />}

            <MenuItem onClick={() => {
                    //handlePrint(null, () => contentToPrintRef.current);
                    if(props.showEstimateScreen) {
                        onDownloadEstimateClick(true);
                    } else {
                        handlePrintInvoiceModalOpen();
                        handleMoreOptionsPopoverClose();
                    }   
                }}
                className={classes.moreOptionsStyles}> 
                    <PrintOutlinedIcon fontSize='small'
                        style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                        {props.showEstimateScreen ? `Print ${label}` : 'Print Invoice'}
            </MenuItem>

        </Popover>)
    }

    const onCreateInvoiceFromEstimateClick = () => {
        props.setInvoiceItemObj({})
        trackGAEvent(props.selectedAccount.name, 'Create Invoice From Estimate - Create button clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setCreateInvoiceAccount(true);
        props.setCustomerObject({...props.invoiceObj?.client});
        props.setEntityObj({...props.invoiceObj?.entity});
        props.setShowPreviewInvoiceScreen(false);
        props.setShowEstimateScreen(false);
        props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        history.push(`/invoice/create`, {estimateObj: {...props.invoiceObj}});
    }

    const onSendEstimateClick = async(email_array, obj) => {

        const {subject, body} = obj;
        setLoading(true);

        try {
            const response = await sendEstimateEmailApi(invoice_account_id, entityObj?.id, props.invoiceObj.id, email_array, subject, body)
            const res = response.data;
            consoleToLog('Response sendEstimateEmailApi: ', res);
            setLoading(false);
            enqueueSnackbar('Mail Sucessfully sent', {variant:'success'});

            let obj = {
                ...props.invoiceObj,
                status: res.status,
                bill_status: res.bill_status,
                can_cancel: res.can_cancel,
                can_close: res.can_cancel,
                can_create_invoice: res.can_create_invoice,
                can_edit: res.can_edit,
                can_reopen: res.can_reopen
            }

            props.setInvoiceItemObj(obj);
            if(props.drawerState === SelectedDrawerState.SHOW_ESTIMATES || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                props.setUpdatedInvoiceObj(obj);
            } 


            trackGAEvent(props.selectedAccount.name, 'Estimate Preview Screen - Email Sent', `${props.user.firstname} ${props.user.lastname}`);

        } catch(e) {
            consoleToLog('Error sendEstimateEmailApi: ', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }


    }

    const showButtonsUi = () => {
        return (
            <Grid item container direction='column' 
                alignItems='center' 
                className="save_invoice"
                
            >
                {   
                    !accessLevelViewer && invoiceType === SendEmailType.NONE && !props.showEstimateScreen ?
                    <Grid item container direction="column">
                        
                        <Button fullWidth 
                            onClick={onSendProformaClick}
                            variant="contained"
                            className={`${classes.buttonStyles} button_purple`}>
                            <SendOutlinedIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                                Send Proforma Invoice
                        </Button>

                        <Button fullWidth  className={classes.buttonStyles}
                            onClick={onSendTaxInvoiceClick}
                            style={{background:'#00a367', color:'#fff'}}>
                            <SendIcon fontSize='small'
                            style={{marginRight:'5px', fontSize:'16px'}} />
                                Send Tax Invoice
                        </Button>
                    </Grid> 
                    : 
                    <Button fullWidth 
                        onClick={() => {
                            trackGAEvent(props.selectedAccount.name, 'Invoice Preview Screen - Send button clicked', `${props.user.firstname} ${props.user.lastname}`);
                            onSendIconClick()
                        }}
                        variant="contained"
                        disabled={accessLevelViewer}
                        className={`${classes.buttonStyles} button_purple`}>
                        <SendIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                            Send Email
                    </Button>  
                }

                {/* <Button fullWidth className={classes.buttonStyles}
                    onClick={onDownloadInvoiceClick}
                    variant="contained" style={{background:'#4a5568', color:'#fff'}} >
                    <PictureAsPdfIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                        Download
                        <Link
                            style={{ display: "none" }}
                            id="invoice_download"
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            download
                        ></Link>
                </Button> */}

                {props.showEstimateScreen && props.invoiceObj.can_create_invoice &&
                <Button fullWidth className={classes.buttonStyles}
                    onClick={onCreateInvoiceFromEstimateClick}
                    disabled={accessLevelViewer}
                    variant="outlined" color='secondary' >
                    <AddCircleOutlineIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                        Create Invoice
                </Button>}

                {props.invoiceObj.can_edit &&
                <Button fullWidth className={classes.buttonStyles}
                    onClick={onEditButtonClick}
                    disabled={accessLevelViewer}
                    variant="outlined" color='secondary' >
                    <EditOutlinedIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                        Edit
                </Button>}

                {/*props.invoiceObj.status !== 'paid' && props.invoiceObj.status !== 'draft' &&
                <Button fullWidth  className={classes.buttonStyles}
                    onClick={handleOpenAddPaymentDrawer}
                    variant="outlined" color="secondary">
                    <MonetizationOnOutlinedIcon fontSize='small'
                        style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                        Add Payment
                </Button>*/}

                {/*props.invoiceObj.status === 'draft' &&
                <Button fullWidth className={classes.buttonStyles}
                    onClick={onMarkAsSentClick}
                    variant="outlined" color="secondary">
                    <CheckIcon fontSize='small'
                        style={{marginRight:'5px', fontSize:'16px'}} />
                        Mark as Sent
            </Button>*/}

                {/* <Button fullWidth 
                    //onClick={onPrintInvoiceClick}
                    onClick={() => handlePrint(null, () => contentToPrintRef.current)}
                    variant="outlined" color="secondary">
                    <PrintOutlinedIcon fontSize='small'
                        style={{marginRight:'5px', color:'green', fontSize:'16px'}} />
                        Print Invoice
                </Button> */}
                <Button fullWidth 
                    //onClick={onPrintInvoiceClick}
                    onClick={handleMoreOptionsPopoverOpen}
                    disabled={accessLevelViewer}
                    variant="outlined" color="secondary">
                    <MoreVertIcon fontSize='small'
                        style={{marginRight:'5px', color:'green', fontSize:'18px'}} />
                        More Options
                </Button>

                {showMoreOptions && showMoreOptionsPopover()}
            </Grid>
        )
    }

    const showInvoiceESignSettings = () => {
        return (
            <Grid item sm={12} className="esign_style">
                <Typography className='font_styles1'>
                    E-Signature
                </Typography>

                <FormControlLabel
                control={
                    <Switch
                        checked={selectedEntityObj?.enable_esign ? selectedEntityObj?.enable_esign : false}
                        onChange={() => handleESignChange(false)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                }
                label="Enable E-Sign"
                />
            </Grid> 
        )
    }

    const handleEnableInvoiceESignModalOpen = () => {
        setOpenESignDialog(true);
    }

    const handleEnableInvoiceESignModalClose = () => {
        setOpenESignDialog(false);
    }

    const handleESignChange = () => {
        if(selectedEntityObj?.enable_esign && selectedEntityObj?.signature) {
            updateEntityESign(false);
        } else if(!selectedEntityObj?.enable_esign && selectedEntityObj?.signature) {
            updateEntityESign(true);
        } else {
            handleEnableInvoiceESignModalOpen();
        }
    }

    const updateEntityESign = (enable_esign) => {
            
        const signature = entityObj?.signature;
        const entity_id = entityObj?.id;

        updateEntityEsignApi(invoice_account_id, entity_id, signature, enable_esign)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEntityEsignApi', res);

                enqueueSnackbar('Invoice e-sign settings updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === entity_id) {
                            item.signature = res.signature;
                            item.enable_esign = res.enable_esign;
                        }
                        return item
                    })
                };

                let obj = {
                    ...selectedEntityObj,
                    signature: res.signature,
                    enable_esign: res.enable_esign
                }

                setSelectedEntityObj(obj);

                entityObj.signature = res.signature;
                entityObj.enable_esign = res.enable_esign;

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                updateInvoiceEntityEsign(res.enable_esign);

            })
            .catch((e) => {
                consoleToLog("Error uploadESignImageApi: ", e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const getDownloadSignedUrl = (entityObj) => {

        getImageDownloadUrlApi(invoice_account_id, entityObj?.signature, 'signature')
            .then((response) => {
                const res =  response.data;
                consoleToLog('Response getImageDownloadUrlApi: ', res);

                setESignUrl(res.download_signed_url);
            })
            .catch((e) => {
                consoleToLog("invoiceAccountUpdateApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const updateInvoiceEntityEsign = (enable_esign) => {
        const invoice_id = props.invoiceObj.id;

        updateInvoiceEntityEsignApi(invoice_account_id, invoice_id, enable_esign)
            .then((response) => {
                const res =  response.data;
                consoleToLog('Response updateInvoiceEntityEsignApi: ', res);

                const obj = {
                    ...props.invoiceObj,
                    enable_esign: res.enable_esign
                }

                props.setInvoiceItemObj(obj);
                props.drawerState === SelectedDrawerState.SHOW_INVOICES && props.setUpdatedInvoiceObj(obj);
            })
            .catch((e) => {
                consoleToLog("updateInvoiceEntityEsignApi error", e.response);
                if (e.response.data && e.response.data.message) {
                    return false;
                }
            });
    }

    const clearAllValues = () => {
        history.push(getUrlAccordingToSelectedComponent(props.drawerState));
        props.setCreateInvoiceAccount(false);
        props.setInvoiceItemObj({});
        props.setCustomerObject({});
        if(props.showPreviewInvoiceScreen) props.setShowPreviewInvoiceScreen(false);
        if(props.showSendInvoiceScreenType !== SendEmailType.NONE) props.setShowSendInvoiceScreenType(SendEmailType.NONE);
        //props.getInvoiceList(1);
        props.handleInvoiceDialogClose();
        props.showEstimateScreen && props.setShowEstimateScreen(false);
    }

    const calculateAmountDue = () => {
        let tds_amount = Number(props.invoiceObj?.tds_amount);
        let due = Number(props.invoiceObj?.due);
        let total = Number(props.invoiceObj?.total);
        if(tds_amount !== 0) {
            if(due !== (total - tds_amount)) {
                return formatAmount(due - tds_amount, currencyObj);
            } else {
                return formatAmount(due, currencyObj);
            }
        } else {
            return formatAmount(due, currencyObj);
        }
    }

    const handleSendReminderChange = async(e) => {
        const value = e.target.checked;
        const flag = (value || reminderObj?.execution_date === null) ? 'enable' : 'disable';

        try {
            const response = await enableDisableEmailReminderApi(invoice_account_id, invoice_id, flag);
            const res = response.data;
            consoleToLog("Response enableDisableEmailReminderApi", res);
            enqueueSnackbar(`Reminders ${flag} successfully`, {variant:'success'});

            const invoiceObj = {
                ...props.invoiceObj,
                invoice_reminders: res
            }
            props.setInvoiceItemObj(invoiceObj);
            props.setUpdatedInvoiceObj(invoiceObj);

        } catch (e) {
            consoleToLog("Error enableDisableEmailReminderApi", e);
            e.response && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
            
    }

    const showEnableDisableSendReminder = () => {
        return (
            <Grid item container direction='column' 
                alignItems='center' 
                className="save_invoice"
                style={{marginTop:'16px'}}
            >
                    <Grid item container direction="column" style={{position:'relative'}}>
                        
                        <Typography className='font_styles1'>
                            Reminders
                        </Typography>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={reminderObj?.active && reminderObj.execution_date !== null}
                                    onChange={handleSendReminderChange}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label={reminderObj?.active && reminderObj.execution_date !== null ? 'Disable Reminder' : 'Enable Reminder'}
                        />
                        
                        {
                            reminderObj?.active  && reminderObj.execution_date !== null &&
                            <Typography variant='subtitle1' style={{marginTop:'8px'}}>
                                Next Reminder: {moment(reminderObj?.execution_date).format('DD-MMM-YYYY')}
                            </Typography>
                        }

                    </Grid> 

            </Grid>
        )
    }

    const showSendEmailComponent = () => {
        if(props.showEstimateScreen) {
            return true;
        }

        if(!isInvoiceStatusCancelled(props.invoiceObj.status)) {
            return true;
        }
    }

    const setImageSizeForEstimateStatus = () => {
        let obj = { width: 0, height: 0 }

        if(status === EstimateStatus.CANCELLED) {
            return {...obj, width: '210px', height:'85px'}
        } else if(status === EstimateStatus.APPROVED || status === EstimateStatus.REJECTED || status === EstimateStatus.CLOSED) {
            return {...obj, width: '120px', height:'70px'}
        } else if(status === EstimateStatus.DRAFT || status === EstimateStatus.SENT || status === EstimateStatus.VIEWED || status === EstimateStatus.DOWNLOADED) {
            return {...obj, width: '180px', height:'67.5px'}
        }

        return obj;
    }

    const showApprovedOrRejectedForEstimate = () => {
        let estimateStatus = props.invoiceObj.status === EstimateStatus.SENT || props.invoiceObj.status === EstimateStatus.VIEWED || props.invoiceObj.status === EstimateStatus.DOWNLOADED;
        if(props.showEstimateScreen && props.invoiceObj?.approval_required && props.invoiceObj?.bill_status === 'none' && estimateStatus) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Container className={classes.container} maxWidth="xl" ref={myDivToFocus}>
            <Grid item container>
                <Grid item ref={contentToPrintRef} id='printInvoice'
                    sm={showSendEmail  ? 7 : !isInvoiceStatusCancelled(props.invoiceObj.status) || props.showEstimateScreen ? 9 : 12} 
                    className={showSendEmail ? `preview_invoice_one` :  'preview_invoice_two'}>
                    <Grid item container alignItems='center'
                        justifyContent='space-between' 
                        className={classes.firstRow}>

                        <Grid item sm={5} className={classes.backIconContainer} id="customPrintStyle">
                            <Grid item container alignItems='center'>
                                <Grid item id='hideOnPrint1'>
                                    <ArrowLeftIcon className={classes.backIcon}
                                        onClick={clearAllValues}
                                    />
                                </Grid>

                                <Grid item style={{display:'flex', flexDirection:'column', marginLeft:'16px'}}>
                                    <Typography className='preview_invoice_text_style'>
                                        <b>{label}</b> {sequence_str}
                                    </Typography>
                                    {props.invoiceObj?.reference_number && !props.showEstimateScreen &&
                                    <Typography className='preview_invoice_text_style'>
                                        <b>Reference Number:</b> {props.invoiceObj?.reference_number}
                                    </Typography>}

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={6}>
                            <Grid item container direction={props.invoiceObj?.reference_number ? 'column' : 'row'} justifyContent="flex-end" 
                                alignItems={props.invoiceObj?.reference_number ? 'flex-end' : 'center'}>
                                <Grid item style={{marginRight:'21px'}}>
                                    <Typography className='preview_invoice_text_style'>
                                        <b>Issued: </b>  
                                        {showDate(props.invoiceObj.issue_date)}
                                    </Typography>
                                </Grid>

                                {!props.showEstimateScreen &&
                                <Grid item style={{marginRight:'21px'}}>
                                    <Typography className='preview_invoice_text_style'>
                                        <b>Due: </b> 
                                        {showDate(props.invoiceObj.due_date)}
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid> 

                    <Grid item container alignItems='center'
                        justifyContent='space-between' 
                        className={classes.secondRow}>
                            
                            <Grid item>
                                <Avatar className={classes.avatarStyle} 
                                    style={{background:'white', color:'#202020', border:'1px solid #202020'}}>
                                    {displayInitials(clientName)}
                                </Avatar>
                            </Grid>

                            {over_due && status !== InvoiceStatus.PAID && status !== InvoiceStatus.DRAFT &&
                            !isInvoiceStatusCancelled(status) && !props.showEstimateScreen ?
                            <Grid item className='hideOnPrint'>
                                <img src="/images/eprocessify_pastdue.png" width="180px" height="67.5px" alt='status image'/>
                            </Grid>
                            :
                            <Grid item className='hideOnPrint'>
                                {!props.showEstimateScreen
                                ?
                                <img src={showStatusImages(status)} 
                                    width={status === 'cancelled' ? "210px" : "180px"} 
                                    height={status === 'cancelled' ? "85px" : "67.5px"} 
                                    alt='status image'
                                />
                                :
                                <img src={estimateStatusImage(status)} 
                                    width={setImageSizeForEstimateStatus().width}
                                    height={setImageSizeForEstimateStatus().height}
                                    alt='status image'/>   
                                }
                            </Grid>}

                            <Grid item style={{textAlign:'right'}}>
                                <Typography className='preview_invoice_style'>
                                    {!props.showEstimateScreen && status !== InvoiceStatus.DRAFT && displayInvoiceType(invoiceType)} 
                                    {` ${label}`}
                                </Typography>
                                <Typography className='preview_invoice_desp_style' 
                                    style={{width:"200px", wordWrap:'break-word'}}>
                                    {description}
                                </Typography>
                            </Grid>
                    </Grid> 

                    <div className='modalBorderBottom1'></div>

                    <Grid item container 
                        justifyContent='space-between' 
                        className={classes.thirdRow}>

                            <Grid item sm={4}>
                                <Typography className='bill_label'>
                                    {!props.showEstimateScreen ? 'Bill To:' : 'To:'}
                                </Typography>
                                <Typography className='client_owner_name'
                                    style={{display:'flex', alignItems:'center', position:'relative'}}>
                                    {clientName}
                                    <MoreHorizIcon fontSize='small' 
                                        onClick={handleEditClinetPopoverClick}
                                        className={classes.moreHorizIconStyle}/>
                                        {showEditClientPopOver()}
                                </Typography>
                                <Typography className='clientOwner_address'>
                                        <div>{clientAddress}</div>
                                        <div>{clientState && `${clientState},`}, {clientCountry}</div>
                                        {
                                            clientContacts && clientContacts.length > 0 &&
                                            clientContacts.slice(0, 1).map((contact) => {
                                                return <div key={contact.id}>
                                                        {
                                                            contact.email && <div> Email - {contact.email} </div>
                                                        }
                                                        {
                                                            contact.phone_number && <div> Phone - {contact.phone_number} </div>
                                                        }
                                                        </div>
                                            })
                                        }
                                        {/* {
                                            clientMobile &&
                                            <div>Tel: {clientMobile}</div>
                                        }
                                        {
                                            clientEmail &&
                                            <div>Email: {clientEmail}</div>
                                        } */}
                                        {/* {client_taxId &&
                                            <div>Tax Id: {client_taxId}</div>
                                        } */}
                                        {
                                            clientUserDefinedFields && clientUserDefinedFields.length > 0 && 
                                            clientUserDefinedFields.map(udf => {
                                                return (udf.value !== '' && udf.show_invoice) ?
                                                <div key={udf.value}>{udf.name}: {udf.value}</div>
                                                :
                                                <div></div>
                                            })
                                        }
                                </Typography>
                            </Grid>

                            <Grid item sm={4} style={{textAlign:'right'}}>
                                    <Typography className='bill_label'>
                                        {!props.showEstimateScreen ? 'Bill From:' : 'From:'}
                                    </Typography>
                                    <Typography className='client_owner_name'>
                                        {entityObj?.name}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                        <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                        </div>
                                        <div>{entityObj?.country}</div>
                                        <div>Tel: {entityObj?.mobile}</div>
                                        <div>Email: {entityObj?.email}</div> 
                                        {entityObj?.tax_id &&
                                            <div>Tax Id: {entityObj?.tax_id}</div>
                                        }
                                    </Typography>
                            </Grid>
                    </Grid> 

                    <div className='modalBorderBottom1'></div>

                    <Grid item container alignItems='center'
                        className={classes.thirdRow}>
                            <Grid item sm={12}>
                                <Grid item container
                                    alignItems='center' 
                                    className="table_container">
                                    <Grid item sm={4}>
                                        <Typography variant="subtitle1" 
                                            className='bill_label'>
                                            Item
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            Cost
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={2} className={classes.customColumn3}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            QTY
                                        </Typography>
                                    </Grid>

                                    {
                                        !props.showEstimateScreen &&
                                        <Grid item sm={2} className={classes.customColumn4}>
                                            <Typography variant="subtitle1"
                                                className='bill_label'>
                                                HSN/SAC code
                                            </Typography>
                                        </Grid>
                                    }

                                    <Grid item sm={!props.showEstimateScreen ? 2 : 4} 
                                        className={!props.showEstimateScreen ? classes.customColumn5 : classes.customColumn6}
                                        style={{textAlign:'right'}}>
                                        <Typography variant="subtitle1"
                                            className='bill_label'>
                                            Amount
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                            {
                                items.map((itemObj) => {
                                    const id = props.showEstimateScreen ? itemObj.id : itemObj.invoice_id;
                                    return  <Grid item sm={12} key={id}>
                                                <Grid item container style={{padding:'8px'}}>
                                                    <Grid item sm={4}>
                                                        <Typography className='preview_list_name_style'>
                                                            {itemObj.item_name}
                                                        </Typography>
                
                                                        <Typography className="preview_list_style">
                                                            {itemObj.item_description} 
                                                        </Typography>
                                                    </Grid>
                
                                                    <Grid item sm={2}>
                                                        <Typography className="preview_list_style">
                                                            {/* {`${itemObj.cost.toFixed(2)}₹`} */}
                                                            {formatAmount(itemObj.cost, currencyObj)}
                                                        </Typography>
                                                    </Grid>
                
                                                    <Grid item sm={2} className={classes.customColumn3}>
                                                        <Typography className="preview_list_style">
                                                            {itemObj.quantity.toFixed(2)}
                                                        </Typography>
                                                    </Grid>

                                                    {!props.showEstimateScreen && 
                                                    <Grid item sm={2} className={classes.customColumn4}>
                                                        <Typography className="preview_list_style">
                                                            {itemObj.hsn_code}
                                                        </Typography>
                                                    </Grid>}
                
                                                    <Grid item sm={!props.showEstimateScreen ? 2 : 4} 
                                                        className={!props.showEstimateScreen ? classes.customColumn5 : classes.customColumn6} 
                                                        style={{textAlign:'right'}}>
                                                        <Typography className="preview_list_style">
                                                            {/* {itemObj.price?.toFixed(2)} */}
                                                            {formatAmount(itemObj.price, currencyObj)}
                                                        </Typography>
                                                        {
                                                            itemObj.tax && itemObj.tax.length > 0 && itemObj.tax?.map((taxObj) => {
                                                                return  <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={taxObj.tax_preset_id}>
                                                                        {taxObj.name + ' ' + taxObj.rate.toFixed(3) +  "%" +  '   ' + 
                                                                        `(${taxObj.amount ? formatAmount(taxObj.amount, currencyObj) : 0})`}
                                                                        </Typography>
                                                            })
                                                        }

                                                        {
                                                            itemObj.discount && itemObj.discount.length > 0 && itemObj.discount.map((discount) => {
                                                                return  <Grid item xs={12}>
                                                                            {
                                                                                discount.type === DiscountTypes.PERCENT 
                                                                                ?
                                                                                <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                                    Discounted {`${discount.value}% (${formatAmount(discount.amount, currencyObj)})`}
                                                                                </Typography>
                                                                                :
                                                                                <Typography style={{fontSize:'12px', marginTop:'4px', fontWeight:'500', color:'#4a5568'}} key={discount.value}>
                                                                                    Discounted {` ${formatAmount(discount.amount, currencyObj)}`}
                                                                                </Typography>
                                                                            }
                                                                        </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                                <div style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}></div>
                                            </Grid>    

                                })
                            }
                    </Grid> 

                    <Grid item sm={12} style={{marginTop:'-16px'}}>
                        <Grid item container justifyContent="space-between" className={classes.thirdRow}>
                            <Grid item sm={4} className={classes.paymentClientStyles}>
                                {props.invoiceObj?.show_payment_terms &&
                                <Grid item>
                                    <Typography className='font_styles1'>
                                        PAYMENT TERMS
                                    </Typography>

                                    <Typography className="clientOwner_address" 
                                        dangerouslySetInnerHTML={{__html: props.invoiceObj?.payment_terms}}
                                        >
                                    </Typography>
                                </Grid>}
                                
                                {props.invoiceObj?.show_client_notes &&
                                <Grid item style={{marginTop:props.invoiceObj?.show_payment_terms && '24px', width:'40ch'}}>
                                    <Typography className='font_styles1'> 
                                        CLIENT NOTES
                                    </Typography>

                                    <Typography className='clientOwner_address'
                                        dangerouslySetInnerHTML={{ __html: props.invoiceObj?.client_notes }}>
                                    </Typography>
                                </Grid>}
                            </Grid>

                            <Grid item sm={7} style={{marginRight:'8px'}}>
                                <Grid item sm={7} className={classes.customMarginLeft}>
                                    <Grid item container justifyContent='space-between'> 
                                        <Typography className='list_item_styles'>
                                            Subtotal
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                            {/* {`${props.invoiceObj?.subtotal.toFixed(2)}₹`} */}
                                            {formatAmount(props.invoiceObj?.subtotal, currencyObj)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item sm={7} className={classes.customMarginLeft}>
                                {
                                    props.invoiceObj?.discount.map((discount) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={discount.value}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        Discount
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${formatAmount(discount.amount, currencyObj)}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }
                                </Grid>

                                <Grid item sm={7} className={classes.customMarginLeft}>
                                {
                                    props.invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${taxObj.amount ? formatAmount(taxObj.amount, currencyObj) : 0}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }
                                </Grid>

                                {props.invoiceObj?.tcs && props.invoiceObj?.tcs?.name &&

                                <Grid item sm={7} style={{marginLeft:'auto'}}>
                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop">

                                        <Tooltip title={props.invoiceObj?.tcs?.name} arrow>
                                            <Typography 
                                                className={`list_item_styles ${classes.tdsDisplay}`}>
                                                TCS ({props.invoiceObj?.tcs?.name})
                                            </Typography>
                                        </Tooltip>

                                        <Typography className='list_item_styles'>
                                            {formatAmount(props.invoiceObj?.tcs.value, currencyObj)}
                                        </Typography>
                                        
                                    </Grid>
                                </Grid>}
                                
                                <Grid item sm={7} className={classes.customMarginLeft}>
                                    <div className="bill_invoice"></div>

                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop"> 
                                        <Typography className='list_item_styles'>
                                            Total
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                            {/* {`${props.invoiceObj?.total.toFixed(2)}₹`} */}
                                            {formatAmount(props.invoiceObj?.total, currencyObj)}
                                        </Typography>
                                    </Grid>
                                </Grid>


                                {props.invoiceObj?.tds_type_object?.tds_display &&
                                <Grid item sm={7} style={{marginLeft:'auto'}}>
                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop">
                                        
                                        <Tooltip title={props.invoiceObj?.tds_type_object?.tds_display} arrow>
                                            <Typography 
                                                className={`list_item_styles ${classes.tdsDisplay}`}>
                                                TDS ({props.invoiceObj?.tds_type_object?.tds_display})
                                            </Typography>
                                        </Tooltip>
                                        

                                        <Typography className='list_item_styles'>
                                            {formatAmount(props.invoiceObj?.tds_amount, currencyObj)}
                                        </Typography>
                                        
                                    </Grid>
                                </Grid>}

                                {!props.showEstimateScreen &&
                                <Grid item sm={7} className={classes.customMarginLeft}>
                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop"> 
                                        <Typography className='list_item_styles'>
                                            Paid to date
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.paid.toFixed(2)}₹`} */}
                                        {formatAmount(props.invoiceObj?.paid, currencyObj)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                }   

                                {!props.showEstimateScreen &&
                                <Grid item sm={7} className={classes.customMarginLeft}>
                                    <Grid item container justifyContent='space-between'
                                        className="item_marginTop"> 
                                        <Typography className='list_item_styles'>
                                            Amount Due (INR)
                                        </Typography>

                                        <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.due.toFixed(2)}₹`} */}
                                        {calculateAmountDue()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    {selectedEntityObj?.enable_esign ? <div className="border_bottom_two"></div> : <div className="dottedborderBottom"></div>}

                    {selectedEntityObj?.enable_esign &&
                        <Grid item sm={12} style={{marginTop:'18px', padding: '0px 32px'}}>
                            <Grid item container direction='column' alignItems='flex-end'>
                                
                                {eSignUrl &&
                                    <Grid item style={{position:'relative', top:'3px'}}>
                                        <div className={'eSign_style'}>
                                            <img src={eSignUrl} width='40%' />
                                        </div>
                                        <Typography style={{fontWeight:400, marginTop:'4px'}}>
                                            Authorized Signatory
                                        </Typography>
                                    </Grid>
                                }

                            </Grid>
                            <div className="dashed_border"></div>
                        </Grid>}

                    {props.invoiceObj?.show_payment_stub && !props.showEstimateScreen &&
                    <Grid item sm={12} className={classes.thirdRow} id='hideOnPrint2'>
                        <Grid item container justifyContent="space-between">
                                <Grid item sm={5} className="payment_stubContainer" 
                                    style={{flexBasis:'55%', maxWidth:'55%'}}>
                                    <Typography className='clientOwner_address'>
                                        Bill from:
                                    </Typography>
                                    <Typography className={classes.clientOwnerName}>
                                        {entityObj?.name}
                                    </Typography>
                                    <Typography className='clientOwner_address'>
                                        <div dangerouslySetInnerHTML={{__html: entityObj?.address}}>
                                        </div>
                                        <div>{entityObj?.country}</div>
                                        <div>Tel: {entityObj?.mobile}</div>
                                        <div>Email: {entityObj?.email}</div> 
                                        {entityObj?.tax_id &&
                                            <div>Tax Id: {entityObj?.tax_id}</div>
                                        }
                                    </Typography>   
                                </Grid>

                            <Grid item sm={5} style={{marginRight:'8px'}}>
                                <Grid item container justifyContent='space-between'> 
                                    <Typography className='list_item_styles'>
                                        Client
                                    </Typography>

                                    <Typography style={{textAlign:'right'}} 
                                    className={(props.showSendInvoiceScreenType === SendEmailType.TAX || props.showSendInvoiceScreenType === SendEmailType.PROFORMA) ?
                                        'list_item_client_style' : 'list_item_styles'}>
                                    {props.invoiceObj?.client?.name}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Invoice
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        #{number}
                                    </Typography>
                                </Grid>

                                {
                                    props.invoiceObj?.total_tax.map((taxObj) => {
                                        return <Grid item container justifyContent='space-between'
                                                    key={taxObj.tax_preset_id}
                                                    className="item_marginTop"> 
                                                    <Typography className='list_item_styles'>
                                                        {taxObj.name}
                                                    </Typography>
                
                                                    <Typography className='list_item_styles'>
                                                        {`${taxObj.amount ? formatAmount(taxObj.amount, currencyObj) : 0}`}
                                                    </Typography>
                                                </Grid>
                                    })
                                }

                                <div className="bill_invoice"></div>


                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Due (INR)
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.due.toFixed(2)}₹`} */}
                                        {formatAmount(props.invoiceObj?.due, currencyObj)}
                                    </Typography>
                                </Grid>

                                <Grid item container justifyContent='space-between'
                                    className="item_marginTop"> 
                                    <Typography className='list_item_styles'>
                                        Amount Enclosed
                                    </Typography>

                                    <Typography className='list_item_styles'>
                                        {/* {`${props.invoiceObj?.paid.toFixed(2)}₹`} */}
                                        {formatAmount(props.invoiceObj?.paid, currencyObj)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    // :
                    // showValidityMessage(props.invoiceObj?.validity)
                    }   
                </Grid>

                {showSendEmailComponent() &&
                <Grid item sm={showSendEmail ? 4 : 2} 
                    className={showSendEmail ? classes.customColumn : classes.customColumn2}>
                        {showSendEmail ?
                            //showSendEmailUI()
                            <SendEmailComponent fromPreviewInvoiceComponent={true}
                                invoiceObj={props.invoiceObj}
                                accessLevelViewer={accessLevelViewer}
                                onBackIconClick={onBackIconClick}
                                clearAllValues={clearAllValues}
                                is_email_verified={is_email_verified}
                                onSendEmailClickFromPreviewInvoice={onSendEmailClickFromPreviewInvoice}
                                loading={loading}
                                showEstimateScreen={props.showEstimateScreen}
                                onSendEstimateClick={onSendEstimateClick}
                                showApprovedOrRejectedForEstimate={showApprovedOrRejectedForEstimate}
                                onMarkAsApprovedOrRejectedClick={onMarkAsApprovedOrRejectedClick}
                                handleConfirmDialogOpen={handleConfirmDialogOpen}
                                onMarkAsSentClick={onMarkAsSentClick}
                                apiLoading={apiLoading}
                            />
                            :
                            <>
                            {showButtonsUi()}
                            {!accessLevelViewer && showInvoiceESignSettings()}
                            {/* {showRecurringInvoiceSettingUi()} */}
                            {!accessLevelViewer && !props.showEstimateScreen &&
                            <RecurringInvoiceSettings setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                handleInvoiceDialogClose={props.handleInvoiceDialogClose}/>
                            }
                                {(!accessLevelViewer && props.invoiceObj?.status !== InvoiceStatus.DRAFT && props.invoiceObj?.status !== InvoiceStatus.PAID) && !props.showEstimateScreen &&
                                    <EnableDisableReminders reminderObj={reminderObj}
                                        invoiceObj={invoiceObj}
                                        updateInvoiceFuncObj={props.setInvoiceItemObj}
                                        setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                        invoiceAccountId={invoice_account_id}
                                        invoiceId={invoice_id}
                                    />
                                }

                                {invoiceTransactions && invoiceTransactions.length > 0 &&
                                    <PaymentReceipt invoiceObj={props.invoiceObj}
                                        invoiceTransactions={invoiceTransactions}
                                    />
                                }
                            </>
                    }
                </Grid>     }           

            </Grid>
            {!props.showEstimateScreen && 
            <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                invoiceObj={props.invoiceObj}
                fromPreviewScreen={true}
                handleBankDrawerOpen={handleBankDrawerOpen}
                handleBankDrawerClose={handleBankDrawerClose}
                bankAccObj={bankAccObj}
                setBankAccObj={setBankAccObj}
                setEntityObjForBankAcc={setEntityObjForBankAcc}
                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                setAddTransaction={props.setAddTransaction}
                updateTranscItemInTranscList={props.updateTranscItemInTranscList}
                />}
            <AddEditClientDrawer openClientDrawer={openClientDrawer} 
                    handleClientDrawerClose={handleEditClientDrawerClose}
                    editClientObj={editClientObj}
                    setClientName={setClientName}
                    setClientAddress={setClientAddress}
                    setClientState={setClientState}
                    setClientCountry={setClientCountry}
                    setClientMobile={setClientMobile}
                    setClientEmail={setClientEmail}
                    setClientContact={setClientContact}
                    setClientUserDefinedField={setClientUserDefinedField}
                    clientUserDefinedFields={clientUserDefinedFields}
                    fromPreviewInvoice={true}
                    setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                    fromCreateEditOrPreviewInvoice={true}
                    //addClient={addClient}
            />

            {openESignDialog && <EnableInvoiceESignModal openESignDialog={openESignDialog}
                handleEnableInvoiceESignModalClose={handleEnableInvoiceESignModalClose}
                entityObj={entityObj}
                fromPreviewInvoiceScreen={true}
                selectedEntityObj={selectedEntityObj}
                setSelectedEntityObj={setSelectedEntityObj}
                updateInvoiceEntityEsign={updateInvoiceEntityEsign}/>
            }

            {openBankDrawer &&
                <AddEditBankDrawer openBankDrawer={openBankDrawer}
                    handleBankDrawerClose={handleBankDrawerClose}
                    bankAccountObj={{}}
                    fromDashboardOrAddPayment={true}
                    setBankAccObj={setBankAccObj}
                    entityObjForBankAcc={entityObjForBankAcc}
                    setEntityObjForBankAcc={setEntityObjForBankAcc}
                />
            } 

            {
                openPrintModal &&
                <PrintInvoiceModal openPrintModal={openPrintModal}
                    handlePrintInvoiceModalClose={handlePrintInvoiceModalClose}
                    invoiceObj={invoiceObj}
                    setDownloadInvoiceURL={setDownloadInvoiceURL}
                    />
            }

            {
                openComfirmDialog && <ShowConfirmDialog openComfirmDialog={openComfirmDialog}
                                        handleConfirmDialogClose={handleConfirmDialogClose}
                                        showEstimateScreen={props.showEstimateScreen}
                                        estimateItem={props.showEstimateScreen ? props.invoiceObj : undefined}
                                        invoiceItem={!props.showEstimateScreen ? props.invoiceObj : undefined}
                                        callApiForActionClicked={callApiForActionClicked}
                                        callCancelInvoiceApi={callCancelInvoiceApi}
                                        onMarkAsApprovedOrRejectedClick={onMarkAsApprovedOrRejectedClick}
                                        apiLoading={apiLoading}
                                        
                                    />
            }
            <iframe id="pdfFrame" style={{display:'none'}}></iframe>
        </Container>    
    );
}

const mapStateToProps = (state) => ({
    customerObj: state.invoiceAccounts.customerObj,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    showPreviewInvoiceScreen: state.invoiceAccounts.showPreviewInvoiceScreen,
    showSendInvoiceScreenType: state.invoiceAccounts.showSendInvoiceScreenType,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    drawerState: state.drawerState.setDrawerState,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen,
});

const mapDispatchToProps = (dispatch) => ({
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice)),
    setInvoiceItemObj: (invoice) => dispatch(setInvoiceItemObj(invoice)),
    setCustomerObject: (customerObj) => dispatch(setCustomerObject(customerObj)),
    setShowPreviewInvoiceScreen: (previewInvoiceScreen) => dispatch(setShowPreviewInvoiceScreen(previewInvoiceScreen)),
    setShowSendInvoiceScreenType: (sendInvoiceScreenType) => dispatch(setShowSendInvoiceScreenType(sendInvoiceScreenType)),
    setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),

    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    setShowEstimateScreen: (showEstimateScreen) => dispatch(setShowEstimateScreen(showEstimateScreen)),
    setEntityObj: (entity) => dispatch(setEntityObj(entity))

});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAndSendInvoiceComponent);