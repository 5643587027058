import moment from "moment";
import {SendEmailType} from "../actions/invoiceAccount";
import { SelectedDrawerState } from '../actions/drawer';
import ReactGA from "react-ga4";
import country from "../data/country.json";
import state from "../data/state.json";
import MenuItem from '@material-ui/core/MenuItem';
import { authenticateApi } from "../services/authService";
import currencyCountries from '../data/countries.json';
import { history } from "../routers/AppRouter";
import { Grid, Typography } from "@material-ui/core";

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const emailValidator = (email) => {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (reg.test(email)) {
        return true;
    } else {
        //toast.error('Email is not valid!');
        return false;
    }
};


export const boldString = (client_name, searchClient) => {
    let boldStringText = new RegExp(searchClient, 'igm');
    let result =  client_name.replace(boldStringText, '<strong>' + searchClient + '</strong>');
    return result;
}  

export const showDate = (date) => {
     return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY');
}  

export const showListItemDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MMM Do, YYYY');
}  

export const showPaymentMethodString = (str) => {
    if(str === 'bank') return 'Bank';
    if(str === 'cash') return 'Cash';
    if(str === 'cheque') return 'Cheque';
    if(str === 'upi') return 'UPI';
    if(str === 'credit_card') return 'Credit Card';
    if(str === 'external') return 'external';
}

export const parseString = (str) => {
    //console.log('str', str);
    return str ? str.replace(/<br ?\/?>/ig,"\n") : '';
};

export const showStatusImages  = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'partial') return '/images/eprocessify_partial.png';
    if(status === 'paid') return '/images/eprocessify_paid.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
}

export const estimateStatusImage = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'closed') return '/images/est_closed.png';
    if(status === 'approved') return '/images/est_approved.png';
    if(status === 'rejected') return '/images/est_rejected.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
}

export const statusColor = (status) => {   
    if(status === 'paid') {
        return 'green'
    } else if(status === 'viewed') {
        return '#cca20e'
    } else if(status === 'partial') {
        return '#006644'
    } else if(status === 'draft') {
        return '#828c98'
    } else if(status === 'sent') {
        return '#4c51bf'
    } else if(status === 'downloaded') {
        return '#007bc3'
    } else if(status === 'cancelled') {
        return '#f44336'
    } else if(status === 'approved') {
        return 'green'
    } else if(status === 'rejected') {
        return 'red'
    }
}

export const displayInitials = (name) => {   
    const firstLetters = name
    .split(' ').filter((element) => element.replace(/[^a-zA-Z ]/, ''))
    .map(word => word[0])
    .slice(0, 2)
    .join('');

  return firstLetters.toUpperCase();
} 

export const displayInvoiceType = (invoiceType) => {
    if(invoiceType === SendEmailType.PROFORMA) {
        return 'Proforma';
    } else if(invoiceType === SendEmailType.TAX) {
        return 'Tax';
    } else {
        return '';
    }
}

export const showErrowAndClearReduxOn401 = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const show401ErrorAndClearRedux = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const trackGAPageView= (page) => {
    ReactGA.pageview(page);
}

export const trackGAEvent= (category, action, label) => {
    if(!isDebug()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
}

export const udfTypes = {
    Text: { displayName: "Text", value: "text" },
    Password: { displayName: "Password", value: 'password' },
    ImageOrDocument: { displayName: "Image / Document", value: "file" }
};

export const isPredefinedName = (name) => {
    if (name !== '') {
        return true;
    }
};

//return if valid, return error if not valid
export const isValidUserDefinedFields = (udfArray) => {
    for (var i = 0; i < udfArray.length; i++) {
        for (var j = i + 1; j < udfArray.length; j++) {//repeat
            if (udfArray[i].name === udfArray[j].name) {
                return `User defined field name can not be repeated: ${udfArray[i].name}`;
            }
        }
        //value validations
        if (!udfArray[i].value) {//empty
            return `Value can not be empty at row:${i + 1}`;
        }
    }
}

const {countries} = country;

export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem value={country.code}>{country.name}</MenuItem>
        )
    })
)

const {states} = state;
export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.code}>{state.name}</MenuItem>
        )
    })
)

export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const removeSpace = (str) => {
    return str.replace(/\s/g, '')
}

export const showDefaultClientValues = (option, clientObj) => {
    const itemObj = option.default_value;
    //console.log('*******************', itemObj)
    switch (itemObj) {
        case '{{client.name}}':
            return clientObj?.name;

        case '{{client.address}}':
            return clientObj?.address;

        case '{{client.country}}':
            return clientObj?.country;
        
        case '{{client.state}}':
            return clientObj?.state;
            
        case '{{client.tax_id}}':
            return clientObj?.tax_id
    
        default:
            break;
    }
}

export const AccessLevel = {
    OWNER: 3,
    EDITOR: 2,
    VIEWER: 1,
    getUserAccessLevelValue: function (access_level) {
        switch (access_level) {
            case "owner": return 3;
            case "editor": return 2;
            case "viewer": return 1;

            default:
                break;
        }
    }
};

export const shouldDisable = (user_access, actual_permission) => {
    return !(user_access >= actual_permission);
}

//input: 'abc' output: 'Abc'
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

// phone validation check, key pressed when entering telephone number can only be numbers
export const phoneNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const currencyFormatter = new Intl.NumberFormat('en-IN', {
    style:'currency',
    currency: 'INR',
});

export const InvoiceStatus = {
    PARTIAL : "partial",
    PAID: "paid",
    VIEWED: "viewed",
    DRAFT: "draft",
    SENT: "sent",
    DOWNLOADED: "downloaded",
    CANCELLED: "cancelled"
}

export const InoviceTypes = {
    PROFORMA : "proforma",
    TAX: "tax",
    NONE: "none",
}

export const DiscountTypes = {
    PERCENT : "percent",
    FLAT: "flat",
}

export const otherTaxesTypes = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'GST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'CGST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'SGST' && tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'IGST');
}
export const intraStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "GST" || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "CGST"
                            || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "SGST");
}

export const interStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "IGST")
}

export const OverlayMessage = {
    IMPORT_MSG:"Please wait. We are setting up clients.",
    EXPORT_MSG: "Please wait. We are exporting into spreadsheet!",
    DOWNLOAD_MESSAGE: "Please wait. We are exporting into zip!",
}

export const onlyNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key)) {
        e?.preventDefault();
    }
}

export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};

export const InvoicePlans = {
    FREE: 'free',
    MONTHLY: process.env.REACT_APP_MONTHLY_PLAN,
    YEARLY: process.env.REACT_APP_YEARLY_PLAN
}

export const onUpgradeNowClick = (props) => {
    history.push(AppRoutes.BILLING);
    props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
}

export const setHeightForComponent = (isInvoiceAccountPlanFree, entityLimitExceeded) => {
    if(isInvoiceAccountPlanFree || entityLimitExceeded) {
        return 'calc(100vh - 85px)';
    } else if(!entityLimitExceeded && !isInvoiceAccountPlanFree) {
        return 'calc(100vh - 48px)';
    }
}

export const isInvoiceAccountOnFreePlan = (plan) => {
    return plan?.toLowerCase() === InvoicePlans.FREE ? true : false;
}

export const isRecurringInvoiceActive = (recurring_invoice_obj) => {
    return recurring_invoice_obj && recurring_invoice_obj.status === 'active' ? true : false;
}

export const InvoiceEntityApi = {
    ACTIVE: 'entity_list',
    ARCHIVED: 'archived_entities'
}

const {countries_currency} = currencyCountries;
export const invoiceAccountCountryObj = (selectedAccountCountry) => {
    const countryObj = countries_currency.find(country => country.code === selectedAccountCountry);
    return countryObj
}

export const extractFlagCode = (str) => {
    return str?.split(':')[1]?.split('-')[1]?.toUpperCase()
}

export const isInvoiceAccountCountryIndia = (invoiceAccountCountry) => {
    return invoiceAccountCountry === "IN" ? true : false
}

export const getCountryObj = (countries, code) => {
    return countries.find((country) => country.code === code);
}

export const getStateObj = (states, stateCode, countryCode) => {
    return stateCode ? states.find((state) => state.state_code === stateCode && state.country_code === countryCode) : undefined;
}

export const AppRoutes = {
    DASHBOARD: '/dashboard',
    INVOICES: '/invoices',
    TRANSACTIONS: '/transactions',
    MEMBERS: '/members',
    CLIENTS: '/clients',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ONBOARDING: '/onboarding',
    SETUP: '/setup',
    CLIENT_REPORTS: '/report/client',
    ITEM_REPORTS: '/report/items',
    ESTIMATES: '/estimates',
    BILLING: '/settings/billing'
}

export const getUrlAccordingToSelectedComponent = (drawerState) => {
    if(drawerState === SelectedDrawerState.SHOW_DASHBOARD) {
        return AppRoutes.DASHBOARD;
    } else if(drawerState === SelectedDrawerState.SHOW_INVOICES) {
        return AppRoutes.INVOICES;
    } else if(drawerState === SelectedDrawerState.SHOW_TRANSACTIONS) {
        return AppRoutes.TRANSACTIONS;
    } else if(drawerState === SelectedDrawerState.SHOW_CLIENTS) {
        return AppRoutes.CLIENTS;
    } else if(drawerState === SelectedDrawerState.SHOW_MEMBERS) {
        return AppRoutes.MEMBERS;
    } else if(drawerState === SelectedDrawerState.SHOW_SETTINGS) {
        return AppRoutes.SETTINGS;
    } else if(drawerState === SelectedDrawerState.SHOW_PROFILE) {
        return AppRoutes.PROFILE;
    } else if(drawerState === SelectedDrawerState.SHOW_ONBOARDING) {
        return AppRoutes.ONBOARDING;
    } else if(drawerState === SelectedDrawerState.SHOW_REPORTS) {
        return AppRoutes.REPORTS;
    }  else if(drawerState === SelectedDrawerState.SHOW_ESTIMATES) {
        return AppRoutes.ESTIMATES;
    }
}

export const isInvoiceStatusCancelled = (status) => {
    return status === InvoiceStatus.CANCELLED ? true : false
}

export const displayInvoiceTypeLabel = (type) => {
    if(type === InoviceTypes.TAX){
        return 'INV'
    }
    else {
        return 'PRO' 
    }
}

export const extractBasePath = (fromBaseComponent) => {
    let pathname = window.location.pathname;
    const parts = pathname.split('/');
    if (parts.length > 2) {
        return `/${parts[1]}`;
    }
    return pathname;
};

export const storeComponentState = (fromEdit, fromCreate, itemList, items, page, total, componentObj, saveState, str, props) => {
    const stateObj = {
        itemList,
        items,
        page,
        total,
        ...(props.fromClientDetails && {
            clientListState: {...props.clientComponentObj},
            clientInfo: {...props.showClientInfo},
            tabValue: props.tabValue,
            clientScrollPosition: props.scrollContainer?.scrollTop
        }),
    };
    saveState(stateObj);
    if(!fromCreate) {
    return fromEdit 
        ? history.push(`/${str}/${componentObj.slug}/edit`, {id: componentObj.id, fromEdit: true}) 
        : history.push(`/${str}/${componentObj.slug}/preview`, {id: componentObj.id, fromEdit: false});
    }
}

export const showValidityMessage = (validity) => {
    return (    
        <Grid item sm={12} style={{marginTop:'16px', padding:'0px 24px 16px 24px' }}>
            <Typography className='validitySyle'>
                {`This estimate is valid for ${validity} days from the date issued. After this period, 
                the terms and pricing may be subject to change. If you need more time or have any questions, 
                please let us know before the validity period expires.`}
            </Typography>
        </Grid>
    )
}

export const EstimateStatus = {
    DRAFT: 'draft',
    SENT: 'sent',
    VIEWED: 'viewed',
    DOWNLOADED: 'downloaded',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CLOSED: 'closed',
    CANCELLED: 'cancelled'
}

export const EstimateStatusColor = (status) => {   
    if(status === 'draft') {
        return '#828c98';
    } else if(status === 'sent') {
        return '#4c51bf';
    } else if(status === 'viewed') {
        return '#cca20e';
    } else if(status === 'downloaded') {
        return '#007bc3';
    } else if(status === 'approved') {
        return 'green';
    } else if(status === 'rejected') {
        return '#8b0000';
    } else if(status === 'closed') {
        return '#828c98';
    } else if(status === 'cancelled') {
        return '#f44336';
    }
}

export const BillStatusColor = (status) => {   
    if(status === 'none') {
        return '#cccccc'
    } else if(status === 'partial') {
        return '#ff9800'
    } else if(status === 'invoiced') {
        return '#4caf50'
    } 
}

export const displayStatus = (status) => {
    if(status === 'none') return 'Not Invoiced';
    else if(status === 'partial') return 'Partially Invoiced';
    else if(status === 'invoiced') return 'Invoiced';
    //else return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
}