import React, {useState, useEffect} from 'react';
import { Typography, ListItem, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'; 
import {connect} from 'react-redux';  
import { useIsMount } from './useIsMount';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import {setCreateInvoiceAccount} from '../actions/invoiceAccount';
import { trackGAEvent, shouldDisable, AccessLevel } from '../util/AppUtil';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import {AppRoutes} from '../util/AppUtil';
import { history } from '../routers/AppRouter';
import { useLocation , useParams} from 'react-router-dom';
import {Accordion, AccordionSummary, AccordionDetails, List} from '@material-ui/core';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';


const useStyles = makeStyles((theme) => ({
    leftmenu : {
        height: 'calc(100vh - 48px)',
        flexGrow: 1,
        backgroundColor: "white", 
        transition: ".5s", 
        overflow: "auto", 
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    icons : {
        marginRight:'8px',
        marginLeft: '2px', 
        fontSize: '16px',
        lineHeight: 0
    },
    listStyle: {
       marginLeft:'auto !important',
       color: '#ccc',
       padding:'0 4px 0px 8px',
    },
    labels : {
        padding:'10px 18px',
        marginTop: '5px',
        marginBottom: '10px'
    },
    rightdownicon : {
        marginRight: '3px',
        marginTop:'8px',
        width: '1.5em',
        height: '1.5em',
        fontSize: '1rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        }
    },
    MuiAccordionSummaryroot : {
        '&.Mui-expanded' : {
            marginBottom: '-3px'
        }
    },
    MuiAccordionSummaryexpandIcon : {
        transform: 'rotate(-90deg)',
        '&.Mui-expanded' : {
        transform: 'rotate(0deg)'
        }
    },
    detail : {
        padding: '0px 5px 0px 28px',
        display: "block"
    },
    text : {
        color:'#202020',
        fontWeight: 600,
    },
    listitem : {
        fontSize: '15px',
        fontWeight: 400,
        color:'#202020',
        '&:first-child' : {
            marginTop:'-8px'
        },
        '&:selected' : {
            background:'transparent'
        }    
    },
    onHover: {
        '& .settingsIcon': {
            visibility:'hidden'
        },
        '&:hover' : {
            '& .settingsIcon' : {
                visibility:'visible'
            }
        }
    },
    estimateSettingsIcon: {
        fontSize: '16px',
        lineHeight: 0
    }
}));

const LeftMenuComponent = (props) => {
    const classes = useStyles();
    const isMount = useIsMount();
    const location = useLocation();
    const pathname = location?.pathname;

    useEffect(() => {
        switch (pathname) {
            case AppRoutes.DASHBOARD:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
                break;
            case AppRoutes.INVOICES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICES);
                break;
            case AppRoutes.TRANSACTIONS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRANSACTIONS);
                break;
            case AppRoutes.CLIENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENTS);
                break;
            case AppRoutes.MEMBERS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_MEMBERS);
                break;
            case AppRoutes.SETTINGS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
                break;
            case AppRoutes.PROFILE:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROFILE);
                break;
            case AppRoutes.ONBOARDING:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
                break;
            case AppRoutes.CLIENT_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENT_REPORTS);
                break;
            case AppRoutes.ITEM_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ITEM_REPORTS);
                break;
            case AppRoutes.ESTIMATES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ESTIMATES);
                break;
            
            // Add other cases as needed
            default:
                break;
        }
}, [pathname])

    const proformaEnabled = props.selectedAccount.general_settings?.proforma_enabled;
    const enableTDS = props.selectedAccount?.enable_tds;
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;

    const clearShowInvoice = () => {
        props.setCreateInvoiceAccount(false);
    }

    const clearInvoicesOrTransactionsOrEstimatesState = () => {
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.transactionsComponentState && props.setTransactionComponentState();
        props.estimateComponentState && props.setEstimateComponentState();
    }   

    const onDashboardTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Dashboard tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.DASHBOARD);
        clearShowInvoice();
    }

    const onInvoicesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.transactionsComponentState && props.setTransactionComponentState();
        props.estimateComponentState && props.setEstimateComponentState();
        history.push(AppRoutes.INVOICES);
        clearShowInvoice();
    }

    const onEstimatesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Estimates tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        history.push(AppRoutes.ESTIMATES);
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.transactionsComponentState && props.setTransactionComponentState();
        clearShowInvoice();
    }

    const onTransactionsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Transactions tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRANSACTIONS);
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.estimateComponentState && props.setEstimateComponentState();
        history.push(AppRoutes.TRANSACTIONS);
        clearShowInvoice();
    }

    const onSettingsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Settings tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.SETTINGS);
        clearShowInvoice();
    }

    const onClientsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Clients tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.CLIENTS);
        clearShowInvoice();
    }

    const onAccountMemberClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Members tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.MEMBERS);
        clearShowInvoice();
    }

    const onOnboardingTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Onboarding tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.ONBOARDING);
        clearShowInvoice();
    }

    const onRevenueByClientClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Client Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.CLIENT_REPORTS);
        clearShowInvoice();
    }

    const onRevenueByItemsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Item Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearInvoicesOrTransactionsOrEstimatesState();
        history.push(AppRoutes.ITEM_REPORTS);
        clearShowInvoice();
    }

    const onEstimatesSettingsIconClick = (e) => {
        e.stopPropagation();
        props.handleEstimatesSettingsModalOpen();
    }   

    return (
        <div className={classes.leftmenu} 
                //style={{width: ((proformaEnabled || enableTDS) && props.drawerState === SelectedDrawerState.SHOW_INVOICES && showDrawer) ? '15%' : showDrawer ? '19%' : '0px'}}
                >
                {accessLevelOwner && props.selectedAccount.onboarding &&
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ONBOARDING}   
                    onClick={onOnboardingTabClick}
                >
                    {<i className={`flaticon-settings ${classes.icons}`}></i> }
                    <Typography variant="body1" className={classes.text}>Onboarding</Typography>
                </ListItem>}
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_DASHBOARD}   
                    onClick={onDashboardTabClick}
                >
                    {<i className={`flaticon-folder ${classes.icons}`}></i> }
                    <Typography variant="body1" className={classes.text}>Dashboard</Typography>
                </ListItem>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_INVOICES}   
                    onClick={onInvoicesTabClick}
                >
                    {<LocalAtmOutlinedIcon fontSize='small' className={classes.icons} style={{fontSize:'21px', color:'#888'}}/> }
                    <Typography variant="body1" className={classes.text} style={{marginLeft:'-4px'}}>Invoices</Typography>
                </ListItem>
                <ListItem button className={`${classes.labels} ${classes.onHover}`}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ESTIMATES}   
                    onClick={onEstimatesTabClick}
                >
                    { <i className={`flaticon-file ${classes.icons}`}></i>}
                    <Typography variant="body1" className={classes.text}>{props.selectedAccount.estimate_terminology}</Typography>
                    <Button color='primary' className={'settingsIcon'} style={{marginLeft:'auto', minWidth:'20px'}}
                        onClick={onEstimatesSettingsIconClick}>
                        <i className={`flaticon-settings ${classes.estimateSettingsIcon}`}></i>
                    </Button>
                        
                </ListItem>
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS}   
                    onClick={onTransactionsTabClick}
                    style={{marginBottom:0}}
                >
                    { <i className={`flaticon-clipboards ${classes.icons}`}></i> }
                    <Typography variant="body1" className={classes.text}>Transactions</Typography>  
                </ListItem>
                
                <Accordion elevation={0} defaultExpanded="false" 
                    classes={{
                        root: classes.MuiAccordionroot
                    }}
                >
                    <div>
                        <AccordionSummary
                            style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                            //className={classes.summary}
                            expandIcon={<i className={`flaticon-down-arrow ${classes.rightdownicon}`}></i>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            classes = {{
                                root: classes.MuiAccordionSummaryroot,
                                expandIcon: classes.MuiAccordionSummaryexpandIcon
                            }}
                        >
                            <Typography className={classes.text} variant="body1">Reports</Typography>
                        </AccordionSummary>
                    </div>
                    <AccordionDetails  className={classes.detail}>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_CLIENT_REPORTS}   
                                onClick={onRevenueByClientClick}
                                >
                                    Revenue by Client
                            </ListItem>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_ITEM_REPORTS}   
                                onClick={onRevenueByItemsClick}
                                >
                                    Revenue by Items
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLIENTS}   
                    onClick={onClientsTabClick}
                >
                    {<i className={`flaticon-business-and-trade ${classes.icons}`}></i>}
                    <Typography variant="body1" className={classes.text}>Clients</Typography>  
                </ListItem>
                {!accessLevelViewer &&
                <ListItem button className={classes.labels}
                        selected={props.drawerState === SelectedDrawerState.SHOW_MEMBERS}   
                        onClick={onAccountMemberClick}>
                            <i className={`flaticon-group ${classes.icons}`}></i>
                            <Typography variant="body1" className={classes.text}>Members</Typography>
                </ListItem>}
                <ListItem button className={classes.labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_SETTINGS}   
                    onClick={onSettingsTabClick}
                >
                    {<i className={`flaticon-settings ${classes.icons}`}></i>}
                    <Typography variant="body1" className={classes.text}>Settings</Typography>  
                </ListItem>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    showCreateInvoice: state.invoiceAccounts.showCreateInvoice,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setCreateInvoiceAccount: (createInvoice) => dispatch(setCreateInvoiceAccount(createInvoice))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuComponent);